import React from 'react'

const pageState = {
	menuOpen: false,
	toggleMenuOpen: () => {}
}

const PageContext = React.createContext(pageState)

export default PageContext
