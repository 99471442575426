import React from 'react'
import styled, { FONT_STACK_ALT, keyframes } from '@janbot/alphaville-emotion'

import { makeGlitch } from '../Glitch/Glitch'

export const Announcement = () =>
{
	return (
		<AnnouncementWrap>
			<AnnouncementText>The End<br />Is Nigh</AnnouncementText>
			<GlitchAnnouncementTextLayer>
				<GlitchAnnouncementText>The End<br />Is Nigh</GlitchAnnouncementText>
			</GlitchAnnouncementTextLayer>
		</AnnouncementWrap>
	)
}

const wrapAnimation = keyframes`
	from, 90% {
		visibility: visible;
	}
	90.1%, to {
		visibility: hidden;
	}
`

const AnnouncementWrap = styled.div`
	visibility: hidden;
	animation: ${ wrapAnimation } 3s linear;
`

const AnnouncementText = styled.h2`
	font-family: ${ FONT_STACK_ALT };
	font-weight: normal;
	font-size: 20vh;
	color: #00f;
	text-align: center;

	@media (orientation: portrait) {
		font-size: 10vh;
	}
`

const glitchLayer = keyframes`
	0%, 80% {
		visibility: hidden;
	}
	80.1%, 100% {
		visibility: visible;
	}
`

const GlitchAnnouncementTextLayer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	animation: ${ glitchLayer } 3s linear;
`

const GlitchAnnouncementText = styled(AnnouncementText)`
	${ makeGlitch({ top: true }) };
`
