import { RENDER_MODE_PX, RENDER_MODE_REM } from './constants'

let renderMode = RENDER_MODE_REM

export function setRenderModeToPx()
{
	renderMode = RENDER_MODE_PX
}

export function setRenderModeToRem()
{
	renderMode = RENDER_MODE_REM
}

export function getRenderMode()
{
	return renderMode
}
