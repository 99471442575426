import React, { createContext, Fragment, useContext, useEffect, useState }  from 'react'
import styled, { Z_INDEX_OVERLAY } from '@janbot/alphaville-emotion'

import { Announcement } from './Announcement'
import { Countdown } from './Countdown'
import Svg from './button.svg'
import { NftAbout } from './NftAbout'
import { RenderWhenAlive } from '../Death/Death'
import { Background } from './Background'
import { NftButton } from './NftButton'

export const nftOverlayContext = createContext({
	hasShownAnnouncement: false,
	setHasShownAnnouncement: () => {},
})

const NftOverlayMain = () =>
{
	const { hasShownAnnouncement, setHasShownAnnouncement } = useContext(nftOverlayContext)

	const [showAnnouncement, setShowAnnouncement] = useState(!hasShownAnnouncement)
	const [showAbout, setShowAbout] = useState(false)

	useEffect(() =>
	{
		setHasShownAnnouncement(true)

		const timeout = setTimeout(() => setShowAnnouncement(false), 3000)

		return () => clearTimeout(timeout)
	}, [])

	return <Fragment>
		{showAbout ? <NftAbout onClose={ () => setShowAbout(false) } /> : (
			<OverlayWrap>
				{showAnnouncement ? <Announcement /> :
					<Fragment>
						<Background />
						<NftButton role="button" title="Buy, Collect & Save a piece of Jan Bot." onClick={ () => setShowAbout(true) }>
							<Svg />
						</NftButton>
						<Countdown onClick={ () => setShowAbout(true) } />
					</Fragment>
				}
			</OverlayWrap>)}
	</Fragment>
}

const NftOverlay = () => (
	<RenderWhenAlive>
		<NftOverlayMain />
	</RenderWhenAlive>
)

NftOverlay.context = nftOverlayContext

const OverlayWrap = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	z-index: ${ Z_INDEX_OVERLAY };
	pointer-events: none;
`

export default NftOverlay
