import 'regenerator-runtime/runtime'
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { hydrate, sheet } from '@janbot/alphaville-emotion'
import { isPrerender } from '@janbot/alphaville-common'
import loadable from 'react-loadable'

import Tracker from '../Components/Tracker'
import App from '../Components/App'
import '../assets'

hydrate(window.___EMO_IDS)

if (isPrerender())
{
	// Make sure stylesheet rules are rendered in the DOM when prerendering
	sheet.speedy(false)
}

loadable.preloadReady().then(() =>
{
	const basename = /^\/alphaville/.test(location.pathname) ? '/alphaville' : ''

	ReactDOM.render((
		<Router basename={ basename }>
			<Fragment>
				<App />
				<Route path="/" component={ Tracker } />
			</Fragment>
		</Router>
	), document.getElementById('main'))
})
