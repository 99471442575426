import React, { PureComponent } from 'react'
import {
	LOGO_HEIGHT,
	LOGO_WIDTH
} from '@janbot/alphaville-emotion'

import Box from '../Box'
import Logo from './Logo'

class Scaled extends PureComponent
{
	render()
	{
		const { blinking, scale, ...rest } = this.props

		return (
			<Box.Scaled width={ LOGO_WIDTH } height={ LOGO_HEIGHT } scale={ scale } { ...rest }>
				<Logo boxed={ false } blinking={ blinking } />
			</Box.Scaled>
		)
	}
}

export default Scaled
