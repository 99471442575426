import React from 'react'
import { PropTypes } from 'prop-types'

import Menu from './Menu'
import About from './About'

export const DEFAULT_LINKS = [
	{
		to: '/livelog',
		title: 'live.log'
	},
	{
		to: '/catalog',
		title: 'cata.log'
	},
	{
		to: '/metalog',
		title: 'meta.log'
	},
	// {
	// 	to: '/nft',
	// 	title: 'NFT'
	// }
]

const MainMenu = ({ children, links, about }) =>
{
	return (
		<Menu links={ links }>
			{ children || (about && <About />) }
		</Menu>
	)
}

MainMenu.propTypes = {
	links: Menu.propTypes.links,
	about: PropTypes.bool
}

MainMenu.defaultProps = {
	links: DEFAULT_LINKS,
	about: true
}

export default MainMenu
