import React, { Fragment } from 'react'
import styled, { BREAKPOINT_MAX_TABLET } from '@janbot/alphaville-emotion'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'

import Title from './Title'

const RECENT_THRESHOLD = 30 * 60 * 1000

const Speach = styled.span`
	${ BREAKPOINT_MAX_TABLET } {
		br {
			display: none;
		}
	}
`

const Description = ({ video }) =>
{
	const now = Date.now()
	const recent = now - RECENT_THRESHOLD

	return <Fragment>
		<Speach>This video is about { video.topic.title }.<br /><br />{ ' ' }
		I call it "</Speach><Title video={ video } /><Speach>".<br /><br />{ ' ' }
		I made it { video.created > recent ? 'just now' : `${ distanceInWordsToNow(video.created) } ago` }.</Speach>
	</Fragment>
}

Description.Speach = Speach
Description.Title = Title

export default Description
