const PRERENDER_REGEX = /Prerender/

export default function()
{
	if (typeof window === 'undefined')
	{
		return false
	}

	return PRERENDER_REGEX.test(window.navigator.userAgent)
}
