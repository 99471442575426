import React, { Fragment, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { width } from 'styled-system'
import styled, {
	omitProps,
	BREAKPOINT_MIN_DESKTOP
} from '@janbot/alphaville-emotion'

import Box from '../../Box'
import Description, { DescriptionWrap } from './Description'
import Label, { LabelWrap } from './Label'

const WIDTH_MOBILE = 'calc(100% - 50px)'
const WIDTH_TABLET = 'calc(50% - 50px)'
const WIDTH_DESKTOP = 'calc(100% / 3 - 50px)'
const WIDTH_XL = 'calc(100% / 4 - 50px)'

const WIDTH = [
	WIDTH_MOBILE,
	WIDTH_TABLET,
	WIDTH_DESKTOP,
	WIDTH_XL
]

const Wrap = styled(Link, { shouldForwardProp: omitProps(['width']) })`
	display: block;
	position: relative;
	margin: 0 25px 50px;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	${ width }

	${ BREAKPOINT_MIN_DESKTOP } {
		${ DescriptionWrap } {
			display: none;
		}

		&:hover {
			${ DescriptionWrap } {
				display: block;
			}

			${ LabelWrap } {
				display: none;
			}
		}
	}

`

class VideoLink extends PureComponent
{
	static defaultProps = {
		onClick: null,
		disabled: false,
	}

	onClick = (e) =>
	{
		const { video, onClick } = this.props

		if (onClick !== null)
		{
			onClick(e, video)
		}
	}

	render()
	{
		const {
			video,
			label,
			children,
			disabled,
			className
		} = this.props

		return <Wrap width={ WIDTH } onClick={ this.onClick } to={ `/catalog/view/${ video.id }` } disabled={disabled} className={className}>
			{children ? children : (
				<Fragment>
					{ label && <Label text={ label } /> }
					<Box.Image ratio={ (3 / 4) } src={ video.thumbnail } />
					<Description video={ video } />
					</Fragment>
			)}
		</Wrap>
	}
}

export default VideoLink
