import Menu from './Menu'
import Main from './Main'
import Button from './Button'
import Content from './Content'
import Link from './Link'

Menu.Link = Link
Menu.Content = Content
Menu.Button = Button
Menu.Main = Main

export { DEFAULT_LINKS } from './Main'

export default Menu
