import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Button from './Button'

class LinkButton extends PureComponent
{
	static propTypes = {
		...Button.propTypes,
		link: PropTypes.object,
		to: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object
		])
	}

	static defaultProps = {
		...Button.defaultProps,
		link: {},
		to: null
	}

	render()
	{
		const {
			to,
			link,
			children,
			...rest
		} = this.props

		const Content = <Button { ...rest }>{ children }</Button>

		if (to === null)
		{
			return Content
		}

		if (typeof to === 'string' && to.slice(0, 4) === 'http')
		{
			return <a href={ to } { ...link }>{ Content }</a>
		}

		return <Link to={ to } { ...link }>{ Content }</Link>
	}
}

export default LinkButton
