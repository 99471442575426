import React, { Component, Fragment } from 'react'
import { ThemeProvider } from 'emotion-theming'
import {
	getRenderMode,
	RENDER_MODE_PX,
	insertGlobalStyle,
	THEMES,
	STYLED_SYSTEM,
	DEFAULT_WINDOW_SIZE,
	MIN_WINDOW_SIZE,
	MAX_WINDOW_SIZE
} from '@janbot/alphaville-emotion'

import Head from './Head'
import Context from './Context'
import { Glitch } from '../Glitch/Glitch'


/**
 * @typedef {object} Props
 * @property {boolean} props.inverted Whether or not the 'inverted' theme should be enabled
 */

/**
 * Generic page wrapper, sets global styles and configures the theme provider.
 *
 * @param {Object} props Props
 *
 * @augments {Component<Props>}
 */
class Page extends Component
{
	static defaultProps = {
		...Head.defaultProps,
		inverted: false,
		noGlitch: false,
		noGlitchLoop: false,
	}

	constructor(props)
	{
		super(props)

		this.onResize = this.onResize.bind(this)
	}

	state = {
		menuOpen: false,
		toggleMenuOpen: () => this.toggleMenuOpen()
	}

	componentWillMount()
	{
		insertGlobalStyle()
	}

	componentDidMount()
	{
		this.updateGlobalStyle(this.props)

		window.addEventListener('resize', this.onResize)

		this.onResize()
	}

	componentWillUpdate(props)
	{
		this.updateGlobalStyle(props)
	}

	componentWillUnmount()
	{
		document.body.classList.remove('-inverted')

		window.removeEventListener('resize', this.onResize)
	}

	updateGlobalStyle({ inverted })
	{
		if (inverted)
		{
			document.body.classList.add('-inverted')
		}
		else
		{
			document.body.classList.remove('-inverted')
		}
	}

	toggleMenuOpen = () =>
	{
		this.setState(state => ({
			menuOpen: !state.menuOpen
		}))
	}

	onResize()
	{
		if (typeof window === 'undefined')
		{
			return
		}

		if (getRenderMode() === RENDER_MODE_PX)
		{
			return
		}

		const windowSize = Math.max(Math.min(window.innerWidth, MAX_WINDOW_SIZE), MIN_WINDOW_SIZE)

		const fontScale = `${ ((windowSize / DEFAULT_WINDOW_SIZE) * 50 + 50).toFixed(2) }%`

		document.documentElement.style.fontSize = fontScale
	}

	render()
	{
		const {
			inverted,
			children,
			title,
			description,
			shortDescription,
			url,
			image,
			noGlitch,
			noGlitchLoop
		} = this.props

		let theme = inverted ? THEMES.inverted : THEMES.default

		theme = Object.assign({}, theme, STYLED_SYSTEM)

		const { menuOpen, toggleMenuOpen } = this.state

		return <Context.Provider value={ { menuOpen, toggleMenuOpen } }>
			<Head title={ title } description={ description } shortDescription={ shortDescription } url={ url } image={ image } />
			<ThemeProvider theme={ theme } key="theme-provider">
				{noGlitch ?
					<Fragment>{ children }</Fragment> :
					<Glitch noLoop={noGlitchLoop}>{ children }</Glitch> }
			</ThemeProvider>
		</Context.Provider>
	}
}

export default Page
