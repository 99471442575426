import styled, {
	omitProps,
	BORDER_WIDTH,
	FOREGROUND,
	css
} from '@janbot/alphaville-emotion'

import { glitchColor, makeGlitch } from '../Glitch/Glitch'
import { keysToStyle } from './helpers'

const PROP = 'direction'
const PROP_KEYS = [
	'up',
	'left'
]

const direction = keysToStyle(
	PROP,
	PROP_KEYS,
	() => 'transform',
	(name, included) => [`${ name === 'left' ? 'scaleX' : 'scaleY' }(${ included ? -1 : 1 })`]
)

const Svg = styled('svg', { shouldForwardProp: omitProps(['direction']) })`
	width: 100%;
	height: 100%;
	stroke: ${ ({ theme }) => (theme ? theme.FOREGROUND : FOREGROUND) };
	stroke-width: ${ BORDER_WIDTH };
	overflow: visible;

	${ direction }

	${ ({ theme, ...props }) => theme.glitch && css`
		${ makeGlitch({ ...theme.glitch, transforms: direction(props) }) }
	stroke: ${ glitchColor };
	` }
`

export default Svg
