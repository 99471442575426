import React from 'react'

/**
 * 0-pad a number.
 *
 * @param {string|number} number Input value
 * @param {number} i The number
 * @returns {string} The 0-padded number as string
 */
export function pad(number, i = 2)
{
	return (new Array(i + 1).join('0') + number).slice(-i)
}

/**
 * Convert a number of frames to a timecode string.
 *
 * @param {number} value Input value
 * @param {number} fps Frames per second
 * @param {boolean} [ms=true] Set to false to show number of frames instead of milliseconds at the end of the timecode.
 * @returns {string} The timecode string
 */
export function timecode(value, fps, ms = true)
{
	value = parseFloat(value) || 0

	const h = Math.floor(value / (3600 * fps)) % 60
	const m = Math.floor(value / (60 * fps)) % 60
	const s = Math.floor(value / fps) % 60
	const f = value % fps

	let remainder = ms ? '.' : ':'

	if (ms)
	{
		remainder += pad((f / fps) * 1000, 3)
	}
	else
	{
		remainder += pad(f)
	}

	return `${ pad(h) }:${ pad(m) }:${ pad(s) }${ remainder }`
}

/**
 * Convert timecode string to a number of frames.
 *
 * @param {string} value Input value
 * @param {number} fps Frames per second
 * @returns {number} The number of frames
 */
export function frame(value, fps)
{
	const parts = value.split(':')
	const h = parts[0]
	const m = parts[1]
	const s = parts[2]

	let f = parts[3] || 0

	const ms = s.split('.')

	if (ms.length > 1)
	{
		f = (ms[1] / 1000) * fps
	}

	return Math.round((((h * 3600) + (m * 60) + (s * 1)) * fps) + (f * 1))
}


/**
 * Convert a numeric value to a timecode string and an timecode string to a number of frames.
 *
 * @param {string|number} value Input value
 * @param {number} [fps=25] Frames per second
 * @param {boolean} [ms=true] Set to false to show number of frames instead of milliseconds at the end of the timecode.
 * @returns {string|number} The converted value
 */
export function convert(value, fps = 25, ms = true)
{
	return typeof value == 'number' ? timecode(value, fps, ms) : frame(value, fps)
}

/**
 * Render a frame number as timecode.
 *
 * @param {Object} props - Properties.
 * @param {number} props.value - The frame number.
 * @param {number} [props.fps] - The number of frames per second (defaults to 25).
 * @param {boolean} [props.ms] - Display the number of milliseconds (defaults to false).
 * @returns {ReactElement} Timecode element.
 */
export default ({ value, fps = 25, ms = true }) =>
{
	const str = timecode(value, fps, ms)

	return <span>{ str }</span>
}
