import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import icon from './icon-200.png'
import {
	title as defaultTitle,
	description as defaultDescription,
	shortDescription as defaultShortDescription
} from '../../texts'
import { escapeRegExp } from '../../helpers'

let _base = null

const Head = ({ title, description, shortDescription, url, image }) =>
{
	url = url || getUrl()
	image = image || getImage()

	return <Helmet>
		<title>{ title }</title>
		<meta name="description" content={ description } />
		<meta property="og:title" content={ title } />
		<meta property="og:description" content={ description } />
		<meta property="og:type" content="website" />
		<meta property="og:url" content={ url } />
		<meta property="og:image" content={ image } />
		<meta property="fb:app_id" content={ FB_APP_ID } />
		<meta name="twitter:card" content="summary" />
		<meta name="twitter:site" content="@janbotfilms" />
		<meta name="twitter:title" content={ title } />
		<meta name="twitter:description" content={ shortDescription } />
		<meta name="twitter:image" content={ image } />
	</Helmet>
}

Head.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	shortDescription: PropTypes.string,
	image: PropTypes.string,
	url: PropTypes.string,
}

Head.defaultProps = {
	title: defaultTitle,
	description: defaultDescription,
	shortDescription: defaultShortDescription,
	image: null,
	url: null
}

function getUrl()
{
	if (typeof window === 'undefined')
	{
		return ''
	}

	return window.location.href
}

function getBase()
{
	if (_base)
	{
		return _base
	}

	if (typeof window === 'undefined')
	{
		return ''
	}

	const { pathname, href } = window.location

	const regex = new RegExp(`${ escapeRegExp(pathname) }$`, 'i')

	_base = href.replace(regex, '')

	return _base
}

function getImage()
{
	return getBase() + icon
}

export default Head
