import {
	FOREGROUND,
	BACKGROUND,
	BORDER_WIDTH,
	BORDER_WIDTH_MOBILE,
	BREAKPOINT_MAX_MOBILE,
	BREAKPOINT_MAX_TABLET,
	FONT_STACK,
	FONT_SIZE,
	FONT_SIZE_SMALL,
	FONT_SIZE_LARGE
} from './constants'

// Themed style method

export const background = ({ theme }) => `background: ${ theme ? theme.BACKGROUND : BACKGROUND };`

export const border = ({ theme }) => `
	border: ${ BORDER_WIDTH } solid ${ theme ? theme.FOREGROUND : FOREGROUND };
	${ BREAKPOINT_MAX_MOBILE } {
		border: ${ BORDER_WIDTH_MOBILE } solid ${ theme ? theme.FOREGROUND : FOREGROUND };
	}
`

export const boxShadow = ({ theme }) => `
	box-shadow: inset 0 0 0 ${ BORDER_WIDTH } ${ theme ? theme.FOREGROUND : FOREGROUND };
	${ BREAKPOINT_MAX_MOBILE } {
		box-shadow: inset 0 0 0 ${ BORDER_WIDTH_MOBILE } ${ theme ? theme.FOREGROUND : FOREGROUND };
	}
`

export const font = ({ theme, large, small }) => `
	font-family: ${ FONT_STACK };
	font-size: ${ large ? FONT_SIZE_LARGE : (small ? FONT_SIZE_SMALL : FONT_SIZE) };
	color: ${ theme ? theme.FOREGROUND : FOREGROUND };
`

export const color = ({ theme }) => `
	color: ${ theme ? theme.FOREGROUND : FOREGROUND };
`

export const mobile = (styles) => `
	${ BREAKPOINT_MAX_MOBILE } {
		${ styles }
	}
`

export const tablet = (styles) => `
	${ BREAKPOINT_MAX_TABLET } {
		${ styles }
	}
`
