import React, { Fragment } from 'react'
import styled, {
	boxShadow,
	LOGO_HEIGHT,
	LOGO_WIDTH,
	css
} from '@janbot/alphaville-emotion'
import { NavLink } from 'react-router-dom'
import { display } from 'styled-system'

import Cursor from '../Cursor'
import { H1 } from '../Text/Header'
import { glitchColor, makeGlitch } from '../Glitch/Glitch'

const Header = styled(H1)`
	${ display }
	width: ${ LOGO_WIDTH }px;
	height: ${ LOGO_HEIGHT }px;
	line-height: ${ LOGO_HEIGHT }px;
	padding: 0;
	text-align: center;
	${ ({ boxed, theme }) => boxed && boxShadow({ theme }) }

	a {
		display: block;
		text-decoration: none;
	}

	${ ({ theme, boxed }) => theme.glitch && css`
		${ boxed && boxShadow({ theme: { FOREGROUND: glitchColor } }) }

		a {
			${ makeGlitch(theme.glitch) }
		}
	` }
`

/**
 * Logo Component.
 *
 * @param {Object} props Properties
 * @param {boolean} props.blink Whether or not the cursor should be blinking
 * @param {boolean} props.boxed Whether or the Logo should have a border
 * @param {string} props.link Whether or the Logo should have a border
 * @returns {ReactElement} The component
 */
const Logo = ({ blinking, boxed, link }) =>
{
	let Content = <Fragment>JAN BOT<Cursor blinking={ blinking } /></Fragment>

	if (link)
	{
		Content = <NavLink to={ link }>{ Content }</NavLink>
	}

	return <Header large boxed={ boxed }>{ Content }</Header>
}

Logo.defaultProps = {
	blinking: false,
	boxed: true,
	display: 'inline-block',
	link: '/'
}

Logo.Styled = Header

export default Logo
