import gql from 'graphql-tag'

const VIDEO_STATS_QUERY = gql`
	query getStats {
		stats {
			videos
			deletedVideos
			savedVideos
			days
		}
	}
`

export default VIDEO_STATS_QUERY
