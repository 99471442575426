import styled from '@janbot/alphaville-emotion'
import {
	shouldForwardProp
} from '@janbot/alphaville-emotion'

import Wrap from '../Wrap'

const OuterWrap = styled(Wrap, { shouldForwardProp })``

OuterWrap.defaultProps = {
	position: 'relative',
	width: '100%',
	flex: '0 1000 auto',
}

export default OuterWrap
