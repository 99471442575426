import PropTypes from 'prop-types'
import { display, space, lineHeight } from 'styled-system'
import styled, {
	css,
	shouldForwardProp,
	FONT_SIZE,
	FONT_SIZE_LARGE,
	FONT_SIZE_SMALL
} from '@janbot/alphaville-emotion'

import { makeGlitch } from '../Glitch/Glitch'

const Text = styled('div', { shouldForwardProp })`
	${ ({ uppercase, large, small }) => css`
		${ uppercase && 'text-transform: uppercase;' }
		font-size: ${ large ? FONT_SIZE_LARGE : (small ? FONT_SIZE_SMALL : FONT_SIZE) };
	` }

	${ display }
	${ space }
	${ lineHeight }

	${ ({ theme }) => theme.glitch && css`
		${ makeGlitch(theme.glitch) }
	` }
`

Text.propTypes = {
	...display.propTypes,
	...space.propTypes,
	...lineHeight.propTypes,
	uppercase: PropTypes.bool,
	large: PropTypes.bool,
	small: PropTypes.bool
}

Text.defaultProps = {
	uppercase: false,
	large: false,
	small: false
}

export default Text
