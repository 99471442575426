import { create } from 'mobx-persist'

import AppState from './AppState'

const appState = new AppState()

const initStorage = create({
	jsonify: true
})

if (typeof window !== 'undefined' && window.localStorage)
{
	initStorage('jb-state', appState)
		.then(appState.onHydrated)
}

export default appState
