import { injectGlobal } from '../emotion'
import clientMonoEot from '../fonts/client-mono.eot'
import clientMonoTtf from '../fonts/client-mono.ttf'
import clientMonoWoff from '../fonts/client-mono.woff'
import clientMonoWoff2 from '../fonts/client-mono.woff2'
import revolveWoff from '../fonts/revolve.woff'
import revolveTtf from '../fonts/revolve.ttf'
import { FONT_FAMILY, FONT_FAMILY_ALT } from '../index'

export default () =>
{
	injectGlobal`
		@font-face {
			font-family: ${ FONT_FAMILY };
			src: url('${ clientMonoEot }');
			src: url('${ clientMonoEot }?#iefix') format('embedded-opentype'),
				url('${ clientMonoWoff2 }') format('woff2'),
				url('${ clientMonoWoff }') format('woff'),
				url('${ clientMonoTtf }') format('truetype');
			font-weight: normal;
			font-style: normal;
		}

		@font-face {
				font-family: ${ FONT_FAMILY_ALT };
				src: url('${ revolveWoff }') format('woff'),
					url('${ revolveTtf }') format('truetype');
				font-weight: normal;
				font-style: normal;
				font-display: block;
		}
	`
}
