export const REM_BASE_SIZE = 16


// Breakpoints

export const BREAKPOINT_MAX_MOBILE_VALUE = 720
export const BREAKPOINT_MIN_TABLET_VALUE = BREAKPOINT_MAX_MOBILE_VALUE + 1
export const BREAKPOINT_MAX_TABLET_VALUE = 1280
export const BREAKPOINT_MIN_DESKTOP_VALUE = BREAKPOINT_MAX_TABLET_VALUE + 1
export const BREAKPOINT_MAX_DESKTOP_VALUE = 1800
export const BREAKPOINT_MIN_HD_VALUE = BREAKPOINT_MAX_DESKTOP_VALUE + 1

export const BREAKPOINT_MAX_MOBILE = `@media (max-width : ${ BREAKPOINT_MAX_MOBILE_VALUE }px)`
export const BREAKPOINT_MIN_TABLET = `@media (min-width : ${ BREAKPOINT_MIN_TABLET_VALUE }px)`
export const BREAKPOINT_MAX_TABLET = `@media (max-width : ${ BREAKPOINT_MAX_TABLET_VALUE }px)`
export const BREAKPOINT_MIN_DESKTOP = `@media (min-width : ${ BREAKPOINT_MIN_DESKTOP_VALUE }px)`
export const BREAKPOINT_MAX_DESKTOP = `@media (max-width : ${ BREAKPOINT_MAX_DESKTOP_VALUE }px)`
export const BREAKPOINT_MIN_HD = `@media (min-width : ${ BREAKPOINT_MIN_HD_VALUE }px)`


// Sizing

export const FONT_BROWSER_CONTEXT = `${ REM_BASE_SIZE }px`
export const DEFAULT_SCALE = '100%'
export const DEFAULT_WINDOW_SIZE = 1440
export const MIN_WINDOW_SIZE = 320
export const MAX_WINDOW_SIZE = 1640

export const PADDING = 50
export const PADDING_MOBILE = 25

export const PADDING_4X3 = `${ 300 / 4 }%`

export const BOX_PADDING = 22
export const HEADER_MARGIN = 25

export const LOGO_WIDTH = 200
export const LOGO_HEIGHT = 75

export const MENU_CONTENT_WIDTH = 600

export const Z_INDEX_HOVER = 100
export const Z_INDEX_MENU = 200
export const Z_INDEX_OVERLAY = 300


// Appearance

export const FOREGROUND = '#fff'
export const BACKGROUND = '#000'

export const BORDER_WIDTH = '3px'
export const BORDER_WIDTH_MOBILE = '3px'


// Typography

export const FONT_FAMILY = "'Client Mono'"
export const FONT_STACK = `${ FONT_FAMILY }, monospace`
export const FONT_FAMILY_ALT = 'rvlv'
export const FONT_STACK_ALT = `${ FONT_FAMILY_ALT }, serif`

export const FONT_SIZE = '18px'
export const FONT_SIZE_SMALL = '13.5px'
export const FONT_SIZE_LARGE = '36px'

export const LINE_HEIGHT = '1.35em'
export const LINE_HEIGHT_SMALL = '1em'
export const LINE_HEIGHT_LARGE = '1.5em'

export const NBSP = '\\a0'


// Render mode

export const RENDER_MODE_PX = 'px'
export const RENDER_MODE_REM = 'rem'


// Themes

export const STYLED_SYSTEM = {
	breakpoints: [
		BREAKPOINT_MIN_TABLET_VALUE,
		BREAKPOINT_MIN_DESKTOP_VALUE,
		BREAKPOINT_MIN_HD_VALUE
	],
}

export const THEMES = {
	default: {
		FOREGROUND,
		BACKGROUND
	},
	inverted: {
		BACKGROUND: FOREGROUND,
		FOREGROUND: BACKGROUND
	}
}
