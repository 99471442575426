import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@janbot/alphaville-emotion'

const finalCountdownSeconds = 300

@inject('statsStore')
@observer
export class Background extends React.Component
{
	render()
	{
		const { statsStore } = this.props
		const secondsRemaining = statsStore.stats.secondsRemaining || 0
		const progress = 1 - (secondsRemaining / finalCountdownSeconds)
		const percentage = Math.round(progress * 100)

		if (secondsRemaining > finalCountdownSeconds)
		{
			return null
		}

		const opacity = `${ percentage }%`

		return (
			<BackgroundMain opacity={ opacity } />
		)
	}
}

const BackgroundMain = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background-color: black;
	opacity: ${ ({ opacity }) => opacity };
`
