import styled, {
	css,
	shouldForwardProp,
	border,
	background,
	calc,
	BORDER_WIDTH,
	BACKGROUND,
	FOREGROUND
} from '@janbot/alphaville-emotion'
import withProps from 'recompose/withProps'
import PropTypes from 'prop-types'
import {
	display,
	order,
	space
} from 'styled-system'

const Checkbox = withProps({
	type: 'checkbox'
})(styled('input', { shouldForwardProp })`
	appearance: none;
	width: 20px;
	height: 20px;
	margin: 0;
	vertical-align: top;

	${ ({ theme }) => css`
		&:checked {
			border: none;
			background: ${ theme ? theme.FOREGROUND : FOREGROUND };
			box-shadow: inset 0 0 0 ${ BORDER_WIDTH } ${ theme ? theme.FOREGROUND : FOREGROUND },
						inset 0 0 0 ${ calc(BORDER_WIDTH, (v) => v * 2) } ${ theme ? theme.BACKGROUND : BACKGROUND };
		}
	` }

	${ display }
	${ order }
	${ space }
	${ border }
	${ background }
`)

Checkbox.propTypes = {
	...display.propTypes,
	...order.propTypes,
	...space.propTypes,
	large: PropTypes.bool
}

Checkbox.defaultProps = {
	display: 'inline-block',
	mr: 10
}

export default Checkbox
