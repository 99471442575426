import gql from 'graphql-tag'

const VIDEO_OF_THE_DAY_QUERY = gql`
	query getVideoOfTheDay($id: String) {
		videoOfTheDay(id: $id) {
			id,
			name
		}
	}
`

export default VIDEO_OF_THE_DAY_QUERY
