import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import loadable from 'react-loadable'
import { Loader, Box, NftOverlay } from '@janbot/alphaville-common'
import Metalog from '@janbot/alphaville-metalog'

const Loading = ({ isLoading, pastDelay }) =>
{
	return isLoading && pastDelay && <Box padded>
		<Loader />
	</Box>
}

const LOADER_DELAY = 400

const Livelog = loadable({
	loader: () => import(/* webpackChunkName: "livelog" */ '@janbot/alphaville-livelog'),
	loading: Loading,
	delay: LOADER_DELAY
})

const Catalog = loadable({
	loader: () => import(/* webpackChunkName: "catalog" */ '@janbot/alphaville-catalog'),
	loading: Loading,
	delay: LOADER_DELAY
})

const Static = loadable({
	loader: () => import(/* webpackChunkName: "static" */ '@janbot/alphaville-static'),
	loading: Loading,
	delay: LOADER_DELAY
})

const Nft = loadable({
	loader: () => import(/* webpackChunkName: "static" */ '@janbot/alphaville-nft'),
	loading: Loading,
	delay: LOADER_DELAY
})


export default () =>
{
	const [hasShownAnnouncement, setHasShownAnnouncement] = useState(false)

	return (
		<NftOverlay.context.Provider value={ { hasShownAnnouncement, setHasShownAnnouncement } }>
			<Switch>
				<Route exact path="/">
					<Redirect to="/livelog" />
				</Route>
				<Route path="/livelog" component={ Livelog } />
				<Route path="/catalog" component={ Catalog } />
				<Route path="/nft" component={ Nft } />
				<Route path="/metalog" component={ Metalog } />
				<Route path="/:day([0-9]{8})" component={ Catalog } />
				<Route component={ Static } />
			</Switch>
		</NftOverlay.context.Provider>
	)
}
