import React from 'react'
import styled, {
	Z_INDEX_OVERLAY,
	FONT_FAMILY_ALT
} from '@janbot/alphaville-emotion'

import Box from '../Box/Box'

const MAX_WIDTH = 900

export const Rip = ({ onClose }) =>
{
	const handleClick = (e) =>
	{
		if (e.target === e.currentTarget)
		{
			onClose()
		}
	}

	return <RipWrap onClick={ handleClick }>
		<ContentWrap>

			<Box padded>
				<Header>
					<h2>JAN BOT</h2>
					<p>2017-2023</p>
					<RipHeader>R.I.P.</RipHeader>
				</Header>

				<p>Jan Bot was an artificial intelligence programme created by artists Bram Loogman and Pablo Núñez Palma in collaboration with Eye Filmmuseum and support from the Netherlands Creative Industries Fund.</p>
				<p>Using some of the latest A.I. of its time, Jan Bot worked day and night producing poetic films inspired by current news and images from early cinema.</p>
				<p>Unlike most smart machines, Jan Bot was not designed to meet narrative conventions. His work was a "carte blanche" meant to reveal the inner processes of a computer mind from the early twenty-first century.</p>
				<p>With Jan Bot's passing in 2023, all his work (more than 25 thousand films) was erased, except for a small hand-picked selection saved by private collectors in the form of unique NFTs. You can find the collection on <a href="https://objkt.com/collection/KT1JYrSZTEC67zHqwQ8k6ScS7wHarwUos2qZ">this link</a>, and more texts about the research behind Jan Bot on his <a href="https://medium.com/janbot">research page</a>.</p>
				<p>Jan Bot is a homage to film history, film culture, and the long tradition of artistic collaboration between humans and machines.</p>

				<p>(c) 2023. All rights reserved.</p>
				<p />
			</Box>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
		</ContentWrap>
	</RipWrap>
}

const Header = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 0;

    + p {
      margin-top: 0;
    }
  }
`

const RipWrap = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${ Z_INDEX_OVERLAY };
	overflow: auto;
	display: flex;
	justify-content: center;
	padding: 120px 40px;

	${ Box.Styled } {
		background: #00f;
	}
`

const RipHeader = styled.h2`
  font-family: ${ FONT_FAMILY_ALT };
  font-size: 60px;
  max-width: 528px;
  text-align: center;
  margin: 40px auto 80px !important;
  font-weight: normal;
`

const ContentWrap = styled.div`
  max-width: ${ MAX_WIDTH }px;
  margin-bottom: 120px;
  padding-bottom: 120px;
`
