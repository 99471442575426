import React from 'react'
import styled from '@janbot/alphaville-emotion'

const OverlayWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 50px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-around;
`

const PlayerOverlay = ({ error, waiting }) =>
{
	let element = <div />

	if (error)
	{
		element = <div>ERROR_</div>
	}
	else if (waiting)
	{
		element = <div>LOADING_</div>
	}

	// TODO: fix double encoding in Webpack ruining this symbol
	// else if (paused)
	// {
	// 	element = <div>▶︎</div>
	// }

	return <OverlayWrap>
		{ element }
	</OverlayWrap>
}

export default PlayerOverlay
