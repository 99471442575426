import { observe, action } from 'mobx'
import { create } from 'mobx-persist'

import { registerUpdateCallback, emitUpdate } from './shareSessionStorage'
import AppSession, { SESSION_STORAGE_KEY } from './AppSession'
import asyncSessionStorage from './asyncSessionStorage'

const appSession = new AppSession()

const initStorage = create({
	storage: asyncSessionStorage,
	jsonify: true
})

if (typeof window !== 'undefined' && window.sessionStorage)
{
	initStorage(SESSION_STORAGE_KEY, appSession)
		.then(appSession.onHydrated)
		.then(() =>
		{
			observe(appSession, ({ name, oldValue, newValue }) =>
			{
				console.log(name, oldValue, newValue)
				emitUpdate({
					[name]: newValue
				})
			})
		})
}

registerUpdateCallback(action((data) =>
{
	const keys = Object.keys(data)

	for (const key of keys)
	{
		if (appSession[key] !== data[key])
		{
			console.log('Received update - set', key, 'to', data[key])
			appSession[key] = data[key]
		}
	}
}))

export default appSession
