import React, { PureComponent } from 'react'

import Box from './Box'

const DEFAULT_RATIO = 4 / 3

class FitBox extends PureComponent
{
	static defaultProps = {
		position: 'absolute',
		ratio: DEFAULT_RATIO
	}

	constructor()
	{
		super(...arguments)

		this.update = this.update.bind(this)
		this.setElement = this.setElement.bind(this)
	}

	state = {
		width: 0,
		height: 0
	}

	componentDidMount()
	{
		this.update()

		window.addEventListener('resize', this.update)
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.update)
	}

	element = null

	setElement(el)
	{
		this.element = el
	}

	update()
	{
		const { ratio } = this.props

		const wrap = this.element.parentNode

		const wrapWidth = wrap.clientWidth
		const wrapHeight = wrap.clientHeight
		const wrapRatio = wrapWidth / wrapHeight

		let width = '100%'
		let height = '100%'

		if (wrapRatio < ratio)
		{
			const targetHeight = wrapWidth / ratio
			const percentage = (targetHeight / wrapHeight) * 100

			height = `${ percentage }%`
		}
		else
		{
			const targetWidth = wrapHeight * ratio
			const percentage = (targetWidth / wrapWidth) * 100

			width = `${ percentage }%`
		}

		this.setState({
			width,
			height
		})
	}

	render()
	{
		const { position, children } = this.props

		const { width, height } = this.state

		return <Box width={ width } height={ height } position={ position } innerRef={ this.setElement }>
			{ children }
		</Box>
	}
}

export default FitBox
