import React from 'react'
import createEmotionStyled from 'create-emotion-styled'

import * as emotion from './emotion'

export default createEmotionStyled(emotion, React)

export * from './mode'
export * from './emotion'
export * from './constants'
export * from './methods'
export * from './utils'
export { default as insertGlobalStyle } from './global'
