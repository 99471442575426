export const GOOGLE = 'google'
export const FACEBOOK = 'facebook'
export const INSTAGRAM = 'instagram'
export const TWITTER = 'twitter'
export const MEDIUM = 'medium'
export const UNKNOWN = 'unknown'

const REGEX_GOOGLE = /google/
const REGEX_FACEBOOK = /facebook/
const REGEX_INSTAGRAM = /instagram/
const REGEX_TWITTER = /twitter/
const REGEX_MEDIUM = /medium/

class Referrer
{
	constructor(url)
	{
		this.url = url
	}

	/**
	 * Get the referrer type.
	 *
	 * @returns {string} The referrer type
	 */
	get type()
	{
		if (this.isGoogle)
		{
			return GOOGLE
		}

		if (this.isFacebook)
		{
			return FACEBOOK
		}

		if (this.isInstagram)
		{
			return INSTAGRAM
		}

		if (this.isTwitter)
		{
			return TWITTER
		}

		if (this.isMedium)
		{
			return MEDIUM
		}

		return UNKNOWN
	}

	get isGoogle()
	{
		return REGEX_GOOGLE.test(this.url)
	}

	get isFacebook()
	{
		return REGEX_FACEBOOK.test(this.url)
	}

	get isInstagram()
	{
		return REGEX_INSTAGRAM.test(this.url)
	}

	get isTwitter()
	{
		return REGEX_TWITTER.test(this.url)
	}

	get isMedium()
	{
		return REGEX_MEDIUM.test(this.url)
	}

	get isUnknown()
	{
		return (this.type === UNKNOWN)
	}
}

export default Referrer
