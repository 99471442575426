import React, { Component, Fragment } from 'react'
import styled from '@janbot/alphaville-emotion'

import Flex from '../../Flex'
import Diagonal from '../../Diagonal/index'
import Box from '../../Box/Box'

export class FaqItem extends Component
{
	state = {
		show: false
	};

	toggle = () =>
	{
		this.setState((prev) => ({
			show: !prev.show
		}))
	};

	render()
	{
		const { question, children } = this.props
		const { show } = this.state

		return (
			<StyledFaqItem>
				<Question padded onClick={ this.toggle }>
					<Flex alignItems="top" justifyContent="space-between">
						<h3>{ question }</h3>
						<span>{show ? '-' : '+'}</span>
					</Flex>
				</Question>
				{ show &&
					<Fragment>
						<Diagonal height={ 24 } expand={ ['top bottom', 'top bottom', 'top bottom left'] } direction={ ['left', 'left', 'left'] } />
						<Box padded>
							{ children }
						</Box>
					</Fragment> }
			</StyledFaqItem>
		)
	}
}

const StyledFaqItem = styled.div`
	& + & {
		margin-top: 32px;
	}
`

export const Question = styled(Box)`
	cursor: pointer;
	text-transform: uppercase;

	h3 {
		margin-bottom: 0;
	}

	span {
		font-size: 40px;
		line-height: 24px;
		height: 30px;
	}
`
