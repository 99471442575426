import React, { PureComponent } from 'react'
import styled, { FOREGROUND } from '@janbot/alphaville-emotion'
import posed from 'react-pose'
import PropTypes from 'prop-types'

import { getPercentage } from './helpers'
import Svg9T from './images/9t.svg'
import Svg12T from './images/12t.svg'
import Svg18T from './images/18t.svg'
import OuterWrap from './OuterWrap'
import {
	Gear12T,
	Gear18T,
	Gear9T,
	REFERENCE_HEIGHT,
	REFERENCE_WIDTH
} from './Animations'

const RATIO_PADDING = getPercentage(REFERENCE_HEIGHT, REFERENCE_WIDTH)

const PosedWrap = posed.div({
	spinning: {},
	paused: {},
	initialPose: 'sync'
})

const InnerWrap = styled(PosedWrap)`
	box-sizing: border-box;
	position: relative;
	width: 100%;
	padding-bottom: ${ RATIO_PADDING };
	flex: 1 1 auto;

	svg {
		position: absolute;

		${ ({ theme }) => `
			fill: ${ theme ? theme.FOREGROUND : FOREGROUND };
			color: ${ theme ? theme.FOREGROUND : FOREGROUND };
		` }
	}
`

class Gears extends PureComponent
{
	static propTypes = {
		spinning: PropTypes.bool,
		width: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		height: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		])
	}

	static defaultProps = {
		spinning: true,
		width: REFERENCE_WIDTH,
		height: 'auto'
	}

	state = {
		pose: 'sync'
	}

	componentDidMount()
	{
		const { spinning } = this.props

		this.setPose(spinning)

		this.syncInterval = setInterval(this.sync, 5000)
	}

	componentWillUpdate({ spinning })
	{
		this.setPose(spinning)
	}

	componentWillUnmount()
	{
		clearInterval(this.syncInterval)
	}

	setPose(spinning)
	{
		const pose = spinning ? 'spinning' : 'paused'

		this.setState({ pose })
	}

	sync = () =>
	{
		const { pose } = this.state

		if (pose !== 'spinning')
		{
			return
		}

		this.setState(() => ({ pose: 'sync' }), () =>
		{
			this.setState({ pose: 'spinning' })
		})
	}

	render()
	{
		const {
			width,
			height,
		} = this.props

		const { pose } = this.state

		return <OuterWrap width={ width } height={ height }>
			<InnerWrap pose={ pose } withParent={ false }>
				<Gear12T rpm={ 18 }>
					<Svg12T />
				</Gear12T>
				<Gear18T rpm={ 12 } reversed={ true }>
					<Svg18T />
				</Gear18T>
				<Gear9T rpm={ 24 }>
					<Svg9T />
				</Gear9T>
			</InnerWrap>
		</OuterWrap>
	}
}

Gears.Styled = OuterWrap

export default Gears
