import React, { Fragment, useEffect } from 'react'
import styled from '@janbot/alphaville-emotion'
import { inject, observer } from 'mobx-react'

@inject('statsStore')
@observer
export class Countdown extends React.Component
{
	componentDidMount()
	{
		const { statsStore } = this.props

		statsStore.loadVideoStats()
	}

	render()
	{
		const { statsStore, onClick } = this.props

		return (
			<CountdownMain
				videos={ statsStore.stats.videos }
				savedVideos={ statsStore.stats.savedVideos }
				deletedVideos={ statsStore.stats.deletedVideos }
				secondsRemaining={ statsStore.stats.secondsRemaining }
				onClick={ onClick }
			/>
		)
	}
}

const split = (time) =>
{
	const days = Math.floor(time / 86400)
	const hours = Math.floor((time - (days * 86400)) / 3600)
	const minutes = Math.floor((time - (days * 86400) - (hours * 3600)) / 60)
	const seconds = Math.floor(time - (days * 86400) - (hours * 3600) - (minutes * 60))

	return { days, hours, minutes, seconds }
}

export const CountdownMain = ({ videos, deletedVideos, onClick, secondsRemaining, savedVideos }) =>
{
	const timeRemaining = split(secondsRemaining)

	const getCount = () => getVideoCount(videos, deletedVideos, secondsRemaining, savedVideos)

	const [count, setCount] = React.useState(getCount())

	useEffect(() =>
	{
		const interval = setInterval(() =>
		{
			const nextCount = getCount()

			setCount(nextCount)
		}, 100)

		return () => clearInterval(interval)
	})

	return <CountdownWrap onClick={ onClick } large={ secondsRemaining < 300 }>
		<p>[{ Object.values(timeRemaining).map(part => `${ part }`.padStart(2, '0')).join(', ') }]<br />[{ Object.keys(timeRemaining).map(part => `'${ part }'`).join(', ') }]</p>
		<p>left until Jan Bot's funeral</p>
		{ count.deletedVideos > 0 && (
			<Fragment>
				<p>
					[{ count.deletedVideos }] videos deleted.<br />
					[{ count.videosRemaining }] videos remaining.
				</p>
			</Fragment>
		) }
	</CountdownWrap>
}

const CountdownWrap = styled.div`
	background: #00f;
	color: #fff;
	padding: 25px;
	text-align: center;
	pointer-events: all;
	cursor: pointer;
	position: relative;
	transform: scale(${ ({ large }) => (large ? 2 : 1) });
`

function getVideoCount(videos, deletedVideos, secondsRemaining, savedVideos)
{
	const count = {
		videosRemaining: videos - deletedVideos,
		deletedVideos
	}

	const finalCountdownSeconds = 300

	if (secondsRemaining > finalCountdownSeconds)
	{
		return count
	}

	// final countdown
	const progress = Math.max((secondsRemaining - 2) / finalCountdownSeconds, 0)

	const newVideosRemaining = Math.min(Math.floor(count.videosRemaining * progress) + savedVideos, count.videosRemaining)
	const extraVideosDeleted = count.videosRemaining - newVideosRemaining

	return {
		videosRemaining: newVideosRemaining,
		deletedVideos: count.deletedVideos + extraVideosDeleted
	}
}

