import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Outer from './Outer'
import Padded from './Padded'

class Box extends PureComponent
{
	static propsTypes = {
		...Outer.propTypes,
		inline: PropTypes.bool,
		padded: PropTypes.bool
	}

	static defaultProps = {
		...Outer.defaultProps,
		padded: false
	}

	render()
	{
		const {
			padded,
			children,
			...rest
		} = this.props

		const Inner = padded ? Padded : Fragment

		return <Outer { ...rest }>
			<Inner>{ children }</Inner>
		</Outer>
	}
}

Box.Styled = Outer

export default Box
