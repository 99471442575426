function clear()
{
	return new Promise((resolve, reject) =>
	{
		try
		{
			sessionStorage.clear()
			resolve(null)
		}
		catch (err)
		{
			reject(err)
		}
	})
}

function getItem(key)
{
	return new Promise((resolve, reject) =>
	{
		try
		{
			const value = sessionStorage.getItem(key)

			resolve(value)
		}
		catch (err)
		{
			reject(err)
		}
	})
}

function removeItem(key)
{
	return new Promise((resolve, reject) =>
	{
		try
		{
			sessionStorage.removeItem(key)
			resolve(null)
		}
		catch (err)
		{
			reject(err)
		}
	})
}

function setItem(key, value)
{
	return new Promise((resolve, reject) =>
	{
		try
		{
			sessionStorage.setItem(key, value)
			resolve(null)
		}
		catch (err)
		{
			reject(err)
		}
	})
}

export default {
	clear,
	getItem,
	removeItem,
	setItem
}
