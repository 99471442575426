import React, { PureComponent } from 'react'

import Button from '../Button'

class MenuButton extends PureComponent
{
	static propTypes = {
		...Button.propTypes,
	}

	static defaultProps = {
		...Button.defaultProps,
		large: true,
		square: true
	}

	render()
	{
		return <Button { ...this.props }>_</Button>
	}
}

export default MenuButton
