import React from 'react';
import styled from '@janbot/alphaville-emotion';

import { FaqItem } from './FaqItem';
import Wrap from '../../Wrap';
import Header from '../../Text/Header';
import Diagonal from '../../Diagonal/index';

export const Faq = () => (
  <StyledFaq>
    <Wrap inline>
      <Header inline boxed>
        FAQ_
      </Header>
      <Diagonal width="auto" height={25} expand="top bottom" direction="left" />
    </Wrap>

    <FaqItem question="Who is Jan Bot?">
      <p>
        Jan Bot is a computer programme created in 2017 by filmmakers Bram
        Loogman and Pablo Núñez Palma to explore the artistic potential of
        artificial intelligence in actualising film heritage.
      </p>
      <p>
        Jan Bot generates an average of 8 videos a day. With a duration of more
        or less thirty seconds, each video is made from two main ingredients:
        Eye Filmmuseum's collection{' '}
        <a href="https://www.eyefilm.nl/en/collection/collections/film/bits-pieces">
          Bits &amp; Pieces
        </a>
        , and today's <a href="https://trends.google.com/">trending topics</a>.
      </p>
      <p>
        To generate a film, Jan Bot uses Computer Vision technology to annotate
        each shot from the archive. Then, it uses Natural Language Processing
        tools to select relevant footage for the day's trending news. Finally,
        it generates various intertitles from news snippets related to the topic
        using its custom algorithms.
      </p>
      <p>
        You can read more about Jan Bot at the <a href="/metalog">Metalog</a>.
      </p>
    </FaqItem>

    <FaqItem question="Why are you killing Jan Bot?">
      <p>
        Almost five years after his release, Jan Bot is still alive and has
        generated more than 25 thousand artworks. But despite such an
        undertaking, Jan's technology is becoming obsolete.
      </p>
      <p>
        Considering the rapid speed at which AI evolves, Jan Bot's artworks are
        no longer avant-garde but artistic snapshots of an old technology. The
        arrival of a new generation of considerably more powerful and
        user-friendly AI tools like GPT chat, Midjourney and Dall-E makes this
        point even more clear. Jan Bot's AI is now archaeology.
      </p>
      <p>
        Next to actualising film heritage, Jan Bot's main purposes has been to
        demistify AI through films that reveal the workings of an artificial
        mind. As the software gets old, Jan Bot's experiments become outdated.
        On these grounds it's been decided to kill Jan Bot and save his legacy
        using one of the most avant-garde technologies in Internet archiving
        today: the Blockchain.
      </p>
    </FaqItem>

    <FaqItem question="Who are Jan Bot's creators?">
      <p>
        Jan Bot's creators are digital artists Pablo Núñez and Bram Loogman.
      </p>
      <p>
        Pablo Núñez Palma is a Chilean-Dutch filmmaker based in Amsterdam. He
        co-directed the experimental feature Manuel de Ribera, which had its
        international premiere at the 2010 Rotterdam International Film
        Festival.
      </p>
      <p>
        Núñez has played different roles in developing audiovisual projects in
        Chile, such as in the documentary Beaverland (Hamburg Film Festival) and
        the documentary web platform MAFI.tv, which premiered at the Doclab
        section of IDFA. In the Netherlands, Pablo followed the master's program
        at the Dutch National Film Academy, where he first started experimenting
        with archival filmmaking for the web. Jan Bot is a direct consequence of
        this line of work.
      </p>
      <p>
        Bram Loogman is a software developer with a background in film and art.
      </p>
    </FaqItem>

    <FaqItem question="What is the Eye Filmmuseum?">
      <p>
        Eye Filmmuseum is the largest Dutch institution dedicated to preserving
        and presenting cinema. Located on Amsterdam's IJ harbour, it manages
        more than 54.000 films from all genres. The museum closely follows the
        latest developments in film by organising new acquisitions, programmes,
        restorations and exhibitions.
      </p>
    </FaqItem>

    <FaqItem question="What is a Blockchain?">
      <p>
        A blockchain is a digital ledger that keeps track of data transactions
        using cryptography and peer-to-peer networks. The technology has existed
        since 2008 and has proven to be highly secure.
      </p>
      <p>
        Jan Bot NFTs are created in the public blockchain called Tezos. Since
        its foundation in 2018, Tezos has been an environmental-friendly
        blockchain (<a href="https://tezos.com/carbon/">proof-of-stake</a>)
        adopted by developers, artists, entrepreneurs, and brands to secure and
        distribute their work.
      </p>
      <p>
        Tezos is popular among artists due to its energy efficiency and low
        minting costs compared to other blockchains. This allows Tezos artists
        to experiment with NFTs in audacious new ways and distribute their work
        in a secure and environmentally friendly manner.
      </p>
      <p>
        Well-known artists minting work in Tezos are Marina Abramovich, John
        Karel, Mike Shinoda and XCopy, among others.
      </p>
    </FaqItem>

    <FaqItem question="What is an NFT?">
      <p>
        A non-fungible token (NFT) is a digital asset that represents ownership
        of a unique item or piece of content. NFTs are stored on a blockchain,
        which is a digital ledger that records transactions and ensures the
        security and integrity of the information stored on it.
      </p>
      <p>
        Think of an NFT as a digital collectable or a digital certificate of
        ownership. Just like a physical collectable, such as a baseball card or
        a rare coin, an NFT is unique and cannot be replaced with another NFT or
        for any other valuable item. However, unlike physical collectables, NFTs
        can be easily bought, sold, and traded online, making them accessible to
        a wider audience and allowing for more convenient and secure
        transactions.
      </p>
      <p>
        People often wonder if purchasing an NFT is the same as downloading a
        digital image from the Internet. The answer is no. NFTs are not the same
        as regular media files. They contain distinguishing metadata and
        traceable history.
      </p>
      <p>
        Think of a Van Gogh painting. It can have many reproductions, some in
        photos, others in canvas. But regardless of their quality, the original
        will never be replaced.
      </p>
    </FaqItem>

    <FaqItem question="What is a digital Wallet?">
      <p>
        A digital wallet is the blockchain equivalent of a user login. It is an
        application that allows you to communicate with a blockchain. The wallet
        contains cryptographically generated keys to identify you as the owner
        of an NFT.
      </p>
      <p>
        Each wallet has an address, which serves as a destination for assets.
        Tezos wallet addresses all begin with "tz." When you allow linking your
        wallet to a website that uses Tezos, your wallet address will display in
        the navigation bar.
      </p>
      <p>
        It's vital to emphasise that assets held on the blockchain are saved on
        the blockchain itself, not in your wallet. Your wallet can only interact
        with assets that you own.
      </p>
    </FaqItem>

    <FaqItem question="How can I create a Tezos Wallet?">
      <p>
        Jan Bot NFTs are issued on the Tezos blockchain. Therefore, you'll need
        a Tezos wallet to get them. There are several wallet alternatives
        available. <a href="https://templewallet.com/">Temple</a>,{' '}
        <a href="https://umamiwallet.com/">Umami</a>,{' '}
        <a href="https://airgap.it/">Airgap</a>, and{' '}
        <a href="https://wallet.kukai.app/">Kukai</a> are among them.
      </p>
      <p>
        Kukai's social sign-in online wallet is the most user-friendly choice
        for people new to Web 3 technologies since it eliminates the need to
        keep seed phrases and keys. To make a wallet, go to Kukai, choose your
        desired social account (Google, Facebook, Twitter, or Reddit), and then
        follow the steps in the pop-ups to link your account.
      </p>
      <p>
        Be warned that using social media to create a wallet does not give
        access to the wallet's seed phrase. If you wish to have a wallet that is
        not linked to your social media profile (e.g. for privacy reasons), you
        will need to create a new wallet in the future. This procedure should be
        relatively simple and straightforward, but it is worth noting.
      </p>
    </FaqItem>

    <FaqItem question="How can I get a Jan Bot NFT?">
      <p>
        You can buy a Jan Bot card at Eye's gift shop or online. By following
        the instructions on the card, you will be able to claim your NFT.
      </p>
      <p>
        <a href="https://objkt.com/collection/KT1JYrSZTEC67zHqwQ8k6ScS7wHarwUos2qZ">
          Buy a Jan Bot card
        </a>
      </p>
    </FaqItem>

    <FaqItem question="Is the card I bought the NFT?">
      <p>
        No, but every card contains a secret QR code that will connect you to a
        form to claim your NFT.
      </p>
      <p>
        In this online form, you can fill in your name, email and wallet
        address. The latter (wallet address) is the only mandatory field, as it
        is needed to send you the NFT.
      </p>
      <p>
        You can read more about digital wallets under the question “what is a
        digital wallet”.
      </p>
      <p>
        IMPORTANT: You can use your card to claim your NFT only until March 13,
        2023. If you haven't claimed your NFT before the end of March, the
        artwork won't be minted, and the piece may be deleted.
      </p>
    </FaqItem>

    <FaqItem question="Can I decide which Jan Bot film I will get?">
      <p>
        With a Jan Bot Card, you cannot decide which film you will get. That
        choice will be part of a curatorial decision by Jan Bot's creators.
      </p>
      <p>
        However, be sure that the film you will receive a film that matches the
        shot appearing on the cover of your card.
      </p>
      <p>
        This situation is different if you purchase a Jan Bot NFT on a secondary
        market (without a card). In that case, you will be able to choose a
        specific piece.
      </p>
    </FaqItem>

    <FaqItem question="Does my NFT have an expiration date?">
      <p>
        No. If the NFT is in your digital wallet, you own the NFT, and that
        won't change until you decide to take further action, like transferring,
        selling or destroying your NFT.
      </p>
      <p>
        However, it is important to highlight that the NFT is not the same as
        the card that gives you access to the NFT. You won't have the NFT until
        you claim it using the secret QR code on your card.
      </p>
    </FaqItem>

    <FaqItem question="What are the properties of an NFT?">
      <p>
        Each NFT has specific information attached to it on the blockchain. For
        Jan Bot, these are the main properties:
      </p>
      <Header>Metadata</Header>
      <p>
        These are additional details about the NFT, like the title and
        description.
      </p>
      <Header>Smart contract</Header>
      <p>
        A smart contract is a code that runs when it receives a transaction on
        the blockchain — for example, a call to mint an NFT. You can view the
        smart contract address for Jan Bot's NFTs on the NFT detail pages.
      </p>
      <Header>IPFS</Header>
      <p>
        IPFS, or the InterPlanetary Files System, is a distributed peer-to-peer
        file system for storing and sharing data. When an NFT is created, the
        artwork is uploaded to IPFS, where it can be viewed via an IPFS link.
        The IPFS link is referenced in the NFT data, and you can find it in the
        properties section.
      </p>
      <p>
        IPFS links aren't permanent; an artwork will only be pinned at that link
        for a set amount of time (for example, two years). If you own an NFT,
        it's best to download the artwork to still have access to the file, even
        if it becomes unpinned in IPFS. If you reupload to IPFS, it will
        generate the same link.
      </p>
      <Header>Royalties</Header>
      <p>
        Royalties are payments made to the artwork's creators when their artwork
        is sold. In the case of Jan Bot, they represent 15% of the total price
        of a sale. A percentage like this is a common practice in
        blockchain-secured art (traditional art often takes higher royalties).
      </p>
      <p>
        This means that if in a secondary market, a Jan Bot film is sold for
        €100, €15 will automatically be transferred to Jan Bot's creators.
      </p>
      <p>
        Royalties are inscribed in the NFT and cannot be changed. On primary
        sales, Jan Bot's royalties will help to cover part of the project's
        production cost, and the profits left will be donated to Eye Filmmuseum
        to preserve film heritage.
      </p>
      <p>
        On sales taking place in secondary markets, royalties will be
        automatically transferred to the digital wallets of Jan Bot's creators.
      </p>
    </FaqItem>

    <FaqItem question="What can I do with my Jan Bot NFT?">
      <p>
        When it comes to digital art, an NFT can be considered a certificate of
        provenance. It proves that you are the sole and only owner of an
        artwork. In this case, a Jan Bot film.
      </p>
      <p>
        In the most practical sense, owning a Jan Bot NFT means that you are a
        digital art collector who supports the preservation of this project. You
        own a unique artwork that you can show, give or sell to others. You will
        be able to access this artwork anytime in your digital wallet.
      </p>
      <p>
        Just as any artwork; you can use your Jan Bot NFT to display it in an
        exhibition space using a digital art screen. You could also use it as a
        profile picture on social media, or as an identity item to be displayed
        in cryptoart markets. Over time, as NFT technology gains more adoption,
        the possibilities of displaying your NFT will grow.
      </p>
      <p>
        Jan Bot NFTs are minted at OBJKT, the largest NFT marketplace in the
        Tezos Blockchain. If you own the NFT, you can connect your wallet to
        OBJKT and put the NFT up for sale there.
      </p>
      <p>
        It should be noted that Jan Bot NFTs are only supported in digital
        wallets compatible with Tezos, such as{' '}
        <a href="https://templewallet.com/">Temple</a>, or{' '}
        <a href="https://wallet.kukai.app/">Kukai</a>. Non-Tezos wallets, like
        Metamask, do not support them.
      </p>
    </FaqItem>

    <FaqItem question="How long does it take the NFT to show in my wallet?">
      <p>
        It will take 48 hours after filling in the form accessible via the
        secret QR link printed on your card. We take longer than regular NFT
        transactions because we manually curate the minting of each artwork to
        reassure a secure and reliable creation. Please be patient and know that
        your NFT will be carefully delivered. In the case of major delays, send
        us an email at hello@jan.bot with a photo of your secret QR code,
        including the four digits printed next to it.
      </p>
    </FaqItem>

    <FaqItem question="Where can I view my NFT?">
      <p>
        Your NFT is always visible in your wallet. How you access your wallet is
        determined by the kind of wallet you use. You may visit Kukai, sign in
        using a seed phrase or a social media account, and then click the
        NFT/Collectibles page.
      </p>
      <p>
        You can also view your NFT on{' '}
        <a href="https://www.objkt.com/profile/janbot">
          objkt.com/profile/janbot
        </a>
        .
      </p>
    </FaqItem>
  </StyledFaq>
);

const StyledFaq = styled.div`
  margin-top: 48px;
`;
