import React from 'react'

import Link from './Link'

const CloseButton = (props) => <Link { ...props }>X</Link>

CloseButton.defaultProps = {
	...Link.defaultProps,
	square: true,
	large: true
}

export default CloseButton
