import PropType from 'prop-types'
import { width, height } from 'styled-system'
import styled, {
	css,
	background,
	shouldForwardProp
} from '@janbot/alphaville-emotion'

const PlayerWrap = styled('div', { shouldForwardProp })`
	${ width }
	${ height }

	video {
		width: 100%;
		height: 100%;
		padding: 0;
		${ background }
	}

	${ ({ playing }) => (!playing ? css`
		video {
			opacity: .5;
			cursor: pointer;
		}
	` : '') }
`

PlayerWrap.propTypes = {
	...width.propTypes,
	...height.propTypes,
	playing: PropType.bool
}

PlayerWrap.defaultProps = {
	width: '100%',
	height: '100%',
	playing: false
}

export default PlayerWrap
