import { observable, action, computed } from 'mobx'
import { persist } from 'mobx-persist'

import Referrer from './Referrer'

export const SESSION_STORAGE_KEY = 'jb-session'

class AppSession
{
	@persist @observable referrerURL = ''

	@observable hydrated = false

	@action onHydrated = () =>
	{
		if (!this.referrerURL && typeof document !== 'undefined')
		{
			this.referrerURL = document.referrer
		}

		this.hydrated = true
	}

	@computed get referrer()
	{
		return new Referrer(this.referrerURL)
	}
}

export default AppSession
