import PropTypes from 'prop-types'
import styled, {
	css,
	border,
	background,
	font,
	shouldForwardProp
} from '@janbot/alphaville-emotion'
import {
	display,
	width,
	flex,
	order,
	space
} from 'styled-system'

const Input = styled('input', { shouldForwardProp })`
	appearance: none;

	${ ({ large }) => css`
		height: ${ large ? 75 : 50 }px;
		line-height: ${ large ? 70 : 45 }px;
		padding: 0 ${ large ? 20 : 15 }px;
	` }

	${ display }
	${ width }
	${ flex }
	${ order }
	${ space }
	${ border }
	${ background }
	${ font }
`

Input.propTypes = {
	...display.propTypes,
	...width.propTypes,
	...flex.propTypes,
	...order.propTypes,
	...space.propTypes,
	large: PropTypes.bool
}

Input.defaultProps = {
	display: 'block',
	width: '100%',
	flex: '1 1 70%',
	large: false
}

export default Input
