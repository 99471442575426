import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import Cookies from 'js-cookie'

const INTRO_DONE_COOKIE_NAME = 'livelog-intro-done'

class AppState
{
	@persist @observable introStarted = false

	@persist @observable introDone = false

	@persist @observable firstSeen = 0

	@persist @observable visits = 0

	@observable hydrated = false

	@action onHydrated = () =>
	{
		// For backward compatibility do a cookie check for the introDone property
		const introDone = Cookies.get(INTRO_DONE_COOKIE_NAME)

		if (introDone)
		{
			this.introStarted = true
			this.introDone = true
			Cookies.remove(INTRO_DONE_COOKIE_NAME)

			// And if a cooke has been set,
			// this means we have see this visitor before
			this.visits += 1
		}

		// Set the 'first seen' timestamp if it wasn't before
		if (this.firstSeen === 0)
		{
			this.firstSeen = Date.now()
		}

		this.visits += 1

		// All done
		this.hydrated = true
	}
}

export default AppState
