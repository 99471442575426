import PropTypes from 'prop-types'
import styled, { BOX_PADDING } from '@janbot/alphaville-emotion'

const PaddedBox = styled.div`
	width: auto;
	height: 100%;
	padding: ${ BOX_PADDING }px;
`

PaddedBox.propTypes = {
	padded: PropTypes.bool
}

PaddedBox.defaultProps = {
	padded: false
}

export default PaddedBox
