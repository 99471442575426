import React, { PureComponent } from 'react'
import { rem, getRemFromPx, isRem, calc } from '@janbot/alphaville-emotion'

function scaleSize(value, scale)
{
	if (typeof value === 'number')
	{
		return getRemFromPx(value * scale)
	}
	else if (isRem(value))
	{
		return parseFloat(value) * scale
	}

	return calc(rem(value), v => (v *= scale))
}

export default function withScale(WrappedComponent)
{
	class ScaledComponent extends PureComponent
	{
		render()
		{
			const {
				children,
				scale = 1,
				origin = 'top left',
				width = null,
				height = null,
				...rest
			} = this.props

			const style = {
				width: width === null ? `${ scale * 100 }%` : scaleSize(width, scale),
				height: height === null ? `${ scale * 100 }%` : scaleSize(height, scale)
			}

			const x = origin.includes('right') ? `${ (1 - scale) / scale * 100 }%` : '0'
			const y = origin.includes('bottom') ? `${ (1 - scale) / scale * 100 }%` : '0'

			if (x || y)
			{
				style.transform = `translate(${ x }, ${ y })`
			}

			return scale > 0 && <WrappedComponent scale={ scale } style={ style } { ...rest }>{
				scale === 1 && children
			}</WrappedComponent>
		}
	}

	return ScaledComponent
}
