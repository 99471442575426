import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Z_INDEX_MENU } from '@janbot/alphaville-emotion'

import PageContext from '../Page/Context'
import Button from './Button'
import Content from './Content'
import Flex from '../Flex'

class Menu extends PureComponent
{
	static propTypes = {
		button: PropTypes.shape(Button.propTypes),
		links: Content.propTypes.links
	}

	static defaultProps = {
		button: Button.defaultProps,
		links: null
	}

	render()
	{
		const {
			button,
			links,
			children,
			...rest
		} = this.props

		return (
			<PageContext.Consumer>
				{({ menuOpen, toggleMenuOpen }) => (
					<Flex
						flexDirection='column'
						alignItems='flex-end'
						position='absolute'
						top={ 50 }
						right={ 50 }
						ml={ 50 }
						zIndex={ Z_INDEX_MENU }>

						<Button { ...button } onClick={ toggleMenuOpen } />

						<Content open={ menuOpen } onClickLink={ toggleMenuOpen } links={ links } { ...rest }>
							{ children }
						</Content>
					</Flex>
				)}
			</PageContext.Consumer>
		)
	}
}

export default Menu
