import { observable, flow, action } from 'mobx'
import ApolloClient from 'apollo-boost'

import query from '../queries/VideosByDay'

const LIMIT = 3

export class VideosByDayStore
{
	@observable error = null
	@observable loadedInitialVideos = false
	@observable loading = false
	@observable endCursor = null
	@observable hasMore = null
	@observable videosByDay = []
	@observable totalCount = 0

	constructor()
	{
		this.client = new ApolloClient({
			uri: GRAPHQL_URL
		})
	}

	init = flow(function* ()
	{
		if (this.loadedInitialVideos)
		{
			return
		}

		const result = yield this.query({
			last: LIMIT,
			before: null
		})

		this.parseResult(result)

		this.loadedInitialVideos = true
	}.bind(this))

	reset = flow(function* ()
	{
		this.error = null
		this.loadedInitialVideos = false
		this.endCursor = null
		this.hasMore = null
		this.videosByDay.clear()
		this.totalCount = 0
		yield this.client.clearStore()

		yield this.init()
	}.bind(this))

	loadMore = flow(function* ()
	{
		if (this.loading)
		{
			return
		}

		const result = yield this.query({
			last: LIMIT,
			before: this.endCursor
		})

		this.parseResult(result)
	}.bind(this))

	query = flow(function* (variables)
	{
		this.loading = true
		this.error = null

		let data = null

		try
		{
			data = yield this.client.query({
				query,
				variables
			})
		}
		catch (error)
		{
			this.error = error
		}

		this.loading = false

		return data
	}.bind(this))

	@action
	parseResult(result)
	{
		if (!result || !result.data)
		{
			console.warn('No data in result')
			return
		}

		const { data } = result
		const { days } = data

		const nodes = days.edges.map((day) =>
		{
			const { node } = day

			const videos = [...node.videos]

			videos.sort((a, b) => b.created - a.created)

			return {
				...node,
				videos
			}
		})

		this.videosByDay.push(...nodes)

		this.endCursor = days.pageInfo.endCursor
		this.hasMore = days.pageInfo.hasPreviousPage

		this.totalCount = data.days.totalCount
	}
}

export default new VideosByDayStore()
