import { width, height } from 'styled-system'
import styled, { omitProps } from '@janbot/alphaville-emotion'

const OuterWrap = styled('div', { shouldForwardProp: omitProps(['width', 'height']) })`
	display: block;
	display: flex;
	align-items: center;
	${ width }
	${ height }
`

export default OuterWrap
