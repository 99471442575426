import { action } from 'mobx'

import { VideoStore } from './videoStore'
import VIDEO_OF_THE_DAY_QUERY from '../queries/VideoOfTheDay'

class VideoOfTheDayStore extends VideoStore
{
	static query = VIDEO_OF_THE_DAY_QUERY

	@action
	parseResult(result, id)
	{
		if (!result || !result.data || !result.data.videoOfTheDay)
		{
			console.warn('No video data in result')
			return null
		}

		const { videoOfTheDay: video } = result.data

		this.videos.set(id, video)

		return video
	}
}

export default new VideoOfTheDayStore()
