import React, { useState } from 'react'
import styled from '@janbot/alphaville-emotion'
import { inject, observer } from 'mobx-react'

import DeathIcon from './death.svg'
import Page from '../Page/Page'
import { Rip } from './Rip'

export const Death = ({ className }) => (
	<StyledDeath className={ className }>
		<DeathIcon />
	</StyledDeath>
)

export const DeathPage = () =>
{
	const [open, setOpen] = useState(false)


	return (
		<Page>
			{open && <Rip onClose={ () => setOpen(false) } />}
			<StyledDeathWrap onClick={ () => setOpen(true) }>
				<Death />
			</StyledDeathWrap>
		</Page>
	)
}

export const StyledDeath = styled.div`
	svg {
		width: 100%;
		height: 100%;
	}
`

const StyledDeathWrap = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	${ StyledDeath } {
		width: 40vw;
		cursor: pointer;
	}

	@media (orientation: portrait) {
		${ StyledDeath } {
			width: 80vw;
		}
	}
`

export const withDeathPage = (Component) => (props) => (
	<RenderWhenAlive fallback={ <DeathPage /> }>
		<Component { ...props }/>
	</RenderWhenAlive>
)


@inject('statsStore')
@observer
export class RenderWhenAlive extends React.Component
{
	render()
	{
		const { statsStore, children, fallback } = this.props

		if (statsStore.isDead)
		{
			return fallback || null
		}

		return children
	}
}
