import { Component } from 'react'
import PropTypes from 'prop-types'
import { timeline, easing } from 'popmotion'

const animationDefinitionType = PropTypes.shape({
	track: PropTypes.string.isRequired,
	duration: PropTypes.number,
	from: PropTypes.any,
	to: PropTypes.any
})

class Timeline extends Component
{
	static propTypes = {
		children: PropTypes.func.isRequired,
		show: PropTypes.bool,
		appear: PropTypes.bool,
		timeline: PropTypes.arrayOf(
			PropTypes.oneOfType([
				animationDefinitionType,
				PropTypes.arrayOf(animationDefinitionType)
			])
		),
		onComplete: PropTypes.func
	}

	static defaultProps = {
		show: true,
		appear: false,
		timeline: {},
		onComplete: null
	}

	state = {}

	componentWillMount()
	{
		this.setupTimeline()
	}

	componentWillUpdate(nextProps)
	{
		const { show } = this.props

		if (show !== nextProps.show)
		{
			this.animateReversed()
		}
	}

	componentWillUnmount()
	{
		this.timeline.stop()
	}

	setupTimeline()
	{
		const { timeline: tracks, onComplete } = this.props

		const animations = tracks.map((definitions) =>
		{
			if (!Array.isArray(definitions))
			{
				definitions = [definitions]
			}

			const array = []

			for (const { track, ...options } of definitions)
			{
				this.setState({ [track]: 0 })

				const definition = {
					track,
					from: 0,
					to: 1,
					...options
				}

				if (definitions.length === 1)
				{
					return definition
				}

				array.push(definition)
			}

			return array
		})

		this.timeline = timeline(animations, { ease: easing.linear })
			.start({
				update: state => this.setState(state),
				complete: onComplete
			})

		const { show, appear } = this.props

		if (show && !appear)
		{
			this.timeline.seek(1)
		}
		else if (!show)
		{
			this.timeline.pause()
		}
	}

	animateReversed()
	{
		this.timeline.pause()
		this.timeline.reverse()
		this.timeline.resume()
	}

	render()
	{
		const { children } = this.props

		return children(this.state)
	}
}

export default Timeline
