import { Component } from 'react'

class Main extends Component
{
	componentDidMount()
	{
		window.location.replace('https://medium.com/janbot')
	}

	render()
	{
		return null
	}
}

export default Main
