import styled, { shouldForwardProp } from '@janbot/alphaville-emotion'
import {
	flex,
	order,
	size,
	space
} from 'styled-system'

const Form = styled('form', { shouldForwardProp })`
	${ flex }
	${ order }
	${ size }
	${ space }
`

Form.propTypes = {
	...flex.propTypes,
	...order.propTypes,
	...size.propTypes,
	...space.propTypes
}

export default Form
