import React, { PureComponent } from 'react'
import styled, { css, keyframes } from '@janbot/alphaville-emotion'

import Outer from './Outer'
import { makeGlitch } from '../Glitch/Glitch'

const ImageOuter = styled(Outer)`
	overflow: hidden;
`

ImageOuter.defaultProps = {
	...Outer.defaultProps
}

const Image = styled.img`
	display: block;
	width: 100%;
	${ ({ full }) => full && css`
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	` }

	${ ({ theme }) => theme.glitch && css`
		${ makeGlitch(theme.glitch) }
		-ms-interpolation-mode: nearest-neighbor;
		image-rendering: pixelated;
		background: transparent;
		animation: ${ imageAnimation({ transform: theme.glitch.top ? 'scale(10) translateX(1px) ' : '' }) } 1s linear infinite;

		${ theme.glitch.top ?
		css`
			filter: invert(9%) sepia(88%) saturate(5705%) hue-rotate(244deg) brightness(112%) contrast(152%);
			mix-blend-mode: hard-light;
			opacity: .4;
		` :
		css`
			filter: invert(100%) sepia(99%) saturate(7497%) hue-rotate(355deg) brightness(105%) contrast(105%);
			mix-blend-mode: overlay;
			opacity: .5;
		` };

		${ theme.glitch.top && css`
			clip-path: polygon(45% 48%,45% 55%,55% 55%,55% 48%)
		` }
	` }
`

const imageAnimation = ({ transform }) => keyframes`
	0% {
		transform: ${ transform }translate(0, 0);
	}
	10% {
		transform: ${ transform }translate(0, 0);
	}
	10.1% {
		transform: ${ transform }translate(${ 11 }px, ${ 11 }px);
	}
	15% {
		transform: ${ transform }translate(${ 11 }px, ${ 11 }px);
	}
	15.1% {
		transform: ${ transform }translate(${ 54 }px${ 54 }px);
	}
	20% {
		transform: ${ transform }translate(${ 54 }px${ 54 }px);
	}
	20.1% {
		transform: ${ transform }translate(0,0);
	}
	30% {
		transform: ${ transform }translate(0, 0);
	}
	30.1% {
		transform: ${ transform }translate(${ -22 }px, ${ -22 }px);
	}
	35% {
		transform: ${ transform }translate(${ -22 }px, ${ -22 }px);
	}
	35.1% {
		transform: ${ transform }translate(${ 72 }px${ 72 }px);
	}
	40% {
		transform: ${ transform }translate(${ 72 }px${ 72 }px);
	}
	40.1% {
		transform: ${ transform }translate(0,0);
	}
	61% {
		transform: ${ transform }translate(0, 0);
	}
	61.1% {
		transform: ${ transform }translate(${ 22 }px, ${ 22 }px);
	}
	65% {
		transform: ${ transform }translate(${ 22 }px, ${ 22 }px);
	}
	65.1% {
		transform: ${ transform }translate(${ 32 }px, ${ 32 }px);
	}
	70% {
		transform: ${ transform }translate(${ 32 }px, ${ 32 }px);
	}
	70.1% {
		transform: ${ transform }translate(0,0);
	}
	83% {
		transform: ${ transform }translate(0,0);
	}
	83.1% {
		transform: ${ transform }translate(${ -32 }px, ${ -32 }px);
	}
	87% {
		transform: ${ transform }translate(${ -32 }px, ${ -32 }px);
	}
	87.1% {
		transform: ${ transform }translate(0,0);
	}
	100% {
		transform: ${ transform }translate(0, 0);
	}

`

class ImageBox extends PureComponent
{
	render()
	{
		const {
			src,
			alt = null,
			ratio = null,
			...props
		} = this.props

		return <ImageOuter ratio={ ratio } { ...props }>
			<Image src={ src } alt={ alt } full={ ratio !== null } />
		</ImageOuter>
	}
}

ImageBox.Styled = ImageOuter

export default ImageBox
