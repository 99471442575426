import React from 'react'

import Box from '../Box'
import Diagonal from '../Diagonal'
import Button from '../Button'
import Wrap from '../Wrap'

const About = () => <Wrap>
	<Box padded maxWidth={ 625 }>
		<p>Jan Bot is the first filmmaking bot hired by EYE Filmmuseum to make short videos from a 100-year-old film archive, taking inspiration from today's trending topics.</p>
		Follow Jan Bot on <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/janbotfilms/">Facebook</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/janbotfilms/">Twitter</a>, and <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/janbotfilms/">Instagram</a>.
	</Box>
	<Diagonal width={ 50 } height={ 25 } expand='top right bottom' />
	<Button.Link to='/about' ml={ 50 }>Read more</Button.Link>
</Wrap>

export default About
