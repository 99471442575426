import PropTypes from 'prop-types'
import styled, {
	css,
	shouldForwardProp
} from '@janbot/alphaville-emotion'
import {
	size,
	space
} from 'styled-system'

const FormGroup = styled('div', { shouldForwardProp })`
	${ ({ flex }) => flex && css`
		display: flex;
		align-items: center;
	` }

	& + ${ () => FormGroup } {
		margin-top: 25px;
	}

	${ size }
	${ space }
`

FormGroup.propTypes = {
	...size.propTypes,
	...space.propTypes,
	flex: PropTypes.bool
}

FormGroup.defaultProps = {
	flex: false
}

export default FormGroup
