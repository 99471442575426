import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withProps from 'recompose/withProps'
import { NavLink } from 'react-router-dom'
import styled, { FONT_SIZE_LARGE } from '@janbot/alphaville-emotion'

const ABSOLUTE_URL_REGEX = /^https?:\/\//i
const ACTIVE_CLASS_NAME = 'nav-link-active'

const linkStyles = () => `
	display: block;
	font-size: ${ FONT_SIZE_LARGE };
	text-decoration: underline;
	text-transform: uppercase;
`

const StyledNavLink = withProps({
	activeClassName: ACTIVE_CLASS_NAME
})(styled(NavLink)`
	${ linkStyles }

	&.${ ACTIVE_CLASS_NAME } {
		text-decoration: none;
	}
`)

const Link = ({ to, children, onClick, className }) => (
	<a href={ to } title={ children } onClick={ onClick } className={ className }>{ children }</a>
)

const StyledLink = styled(Link)`
	${ linkStyles }
`

class MenuLink extends PureComponent
{
	static propTypes = {
		to: NavLink.propTypes.to,
		title: PropTypes.string.isRequired
	}

	static defaultProps = {
		to: null
	}

	render()
	{
		const {
			to,
			onClick,
			title
		} = this.props

		const Component = ABSOLUTE_URL_REGEX.test(to) ? StyledLink : StyledNavLink

		return <Component onClick={ onClick } to={ to }>{ title }</Component>
	}
}

MenuLink.Styled = StyledNavLink

export default MenuLink
