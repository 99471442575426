import React from 'react'
import { LOGO_HEIGHT, LOGO_WIDTH } from '@janbot/alphaville-emotion'

import Diagonal from '../Diagonal'

/**
 * Logo Diagonal Component.
 *
 * @param {Object} props Properties
 * @returns {ReactElement} The component
 */
const LogoDiagonal = ({ ...props }) => <Diagonal { ...props } />

LogoDiagonal.defaultProps = {
	width: LOGO_WIDTH,
	height: LOGO_HEIGHT,
	expand: 'top bottom',
	direction: 'down left',
}

export default LogoDiagonal
