import PropTypes from 'prop-types'
import styled, { font, shouldForwardProp } from '@janbot/alphaville-emotion'
import {
	display,
	size,
	flex,
	order,
	space
} from 'styled-system'

const FormLabel = styled('label', { shouldForwardProp })`
	${ display }
	${ size }
	${ space }
	${ flex }
	${ order }
	${ font }
`

FormLabel.propTypes = {
	...display.propTypes,
	...size.propTypes,
	...space.propTypes,
	...flex.propTypes,
	...order.propTypes,
	large: PropTypes.bool
}

FormLabel.defaultProps = {
	display: 'block',
	flex: '1 1 30%',
	large: false
}

export default FormLabel
