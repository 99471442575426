import React, { PureComponent } from 'react'
import styled, { omitProps } from '@janbot/alphaville-emotion'

import Cursor from '../Cursor'

const Word = styled.span`
	/* white-space: nowrap; */
`

const Invisible = styled.span`
	opacity: 0;
`

const CursorWrap = styled('span', { shouldForwardProp: omitProps(['end']) })`
	${ ({ end }) => !end && 'position: absolute;' }
`


/**
 * @typedef {object} Props
 * @property {string}  text      The text to appear
 * @property {Number}  progress  The animation progress (0-1)
 * @property {boolean}  paused   Whether or not the animation is paused
 */

/**
 * @typedef {object} State
 * @property {Number}   currentWordIndex  The text to appear
 * @property {string}   visible           The visible part of the word currently being typed
 * @property {string}   invisible         The invisible part of the word currently being typed
 */

/**
 * Creates a TypeAnimation instance.
 * Will gradually reveal the text one letter at a time.
 *
 * @augments {PureComponent<Props, State>}
 */
class Type extends PureComponent
{
	static defaultProps = {
		text: '',
		progress: 0,
		paused: false
	}

	constructor(props)
	{
		super(props)

		this.renderWord = this.renderWord.bind(this)
		this.renderWordWrap = this.renderWordWrap.bind(this)

		this.createWordIndex()
	}

	state = {
		currentWordIndex: 0,
		visible: '',
		invisible: ''
	}

	componentDidMount()
	{
		const { progress } = this.props

		this.updateCursor(progress)
	}

	componentWillReceiveProps(nextProps)
	{
		const { progress } = this.props

		if (nextProps.progress !== progress)
		{
			this.updateCursor(nextProps.progress)
		}
	}

	createWordIndex()
	{
		const { text } = this.props

		this.chars = text.split('')
		this.words = text.split(' ')

		this.charToWord = {}
		this.wordStart = {}
		this.wordEnd = {}

		let currentTotalChars = 0

		this.words.forEach((word, wordIndex) =>
		{
			for (let i = 0; i <= word.length; i++)
			{
				this.charToWord[currentTotalChars + i] = wordIndex
			}

			this.wordStart[wordIndex] = currentTotalChars

			currentTotalChars += word.length

			// When this is not the last word add space
			if (wordIndex < this.words.length - 1)
			{
				currentTotalChars++
			}
		})
	}

	updateCursor(progress)
	{
		const charIndex = Math.round(progress * this.chars.length)
		const currentWordIndex = this.charToWord[charIndex]
		const innerIndex = charIndex - this.wordStart[currentWordIndex]

		const visible = this.words[currentWordIndex].slice(0, innerIndex)
		const invisible = this.words[currentWordIndex].slice(innerIndex)

		this.setState({
			currentWordIndex,
			visible,
			invisible
		})
	}

	renderWordWrap(word, index)
	{
		const isLast = index === this.words.length - 1

		return (<Word key={ index }>
			{ this.renderWord(word, index) }
			{ isLast ? null : ' ' }
		</Word>)
	}

	renderWord(word, index)
	{
		const { currentWordIndex } = this.state

		if (index < currentWordIndex)
		{
			return word
		}

		if (index > currentWordIndex)
		{
			return <Invisible>{ word }</Invisible>
		}

		const { visible, invisible } = this.state
		const { paused } = this.props

		return [
			<span key={ `visible-${ index }` }>{ visible }</span>,
			!invisible && <wbr key={ `wbr-${ index }` } />,
			<CursorWrap end={ !invisible } key='cursor'>
				<Cursor blinking={ paused } />
			</CursorWrap>,
			invisible && <Invisible key={ `invisible-${ index }` }>{ invisible }</Invisible>
		]
	}

	render()
	{
		const { progress } = this.props

		return progress ? <span>
			{ this.words.map(this.renderWordWrap) }
		</span> : null
	}
}

export default Type
