import React from 'react'
import styled, { keyframes } from '@janbot/alphaville-emotion'
import { inject, observer } from 'mobx-react'

@inject('statsStore')
@observer
export class NftButton extends React.Component
{
	render()
	{
		const { statsStore, ...rest } = this.props

		const { secondsRemaining } = statsStore.stats

		if (secondsRemaining < 300)
		{
			return null
		}


		return (
			<NftButtonMain { ...rest } />
		)
	}
}

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
		opacity: 1;
	}

	100% {
		transform: rotate(360deg);
		opacity: 1;
	}
`

const NftButtonMain = styled.a`
	position: absolute;
	top: 80px;
	right: 80px;
	width: 240px;
	height: 240px;
	border-radius: 50%;
	pointer-events: all;
	cursor: pointer;
	opacity: 0;
	animation: 20s ${ rotate } 0.5s linear infinite;
	appearance: none;

	@media (orientation: portrait) {
		width: 180px;
		height: 180px;
	}

	svg {
		transition: transform 0.1s ease-in-out;
		width: 100%;
		height: 100%;
	}

	&:hover {
		svg {
			transform: scale(1.03);
		}
	}
`
