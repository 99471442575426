import gql from 'graphql-tag'

const VIDEO_QUERY = gql`
	query getVideo($id: ID!) {
		video(id: $id) {
			id
			name
			created
			deleted
			thumbnail(format: LARGE)
			topic {
				id
				title
				newsItems {
					id
					title
					snippet
					url
					source
				}
				tags {
					id
					name
					_rel {
						score
					}
				}
				related {
					id
					title
					country
					timestamp
				}
			}
			sources {
				url
				format
			}
			previous {
				id
			}
			next {
				id
			}
		}
	}
`

export default VIDEO_QUERY
