import { tween, easing } from 'popmotion'
import styled, { FOREGROUND } from '@janbot/alphaville-emotion'
import posed from 'react-pose'

import { getPercentage } from './helpers'

export const REFERENCE_WIDTH = 324
export const REFERENCE_HEIGHT = 236

const ONE_MINUTE = 60 * 1000

const poses = {
	spinning: {
		rotate: 360,
		transition: {
			rotate: ({ from, rpm, reversed = false }) =>
			{
				return tween({
					from,
					to: reversed ? from - 360 : from + 360,
					key: 'rotate',
					duration: ONE_MINUTE / rpm,
					ease: easing.linear,
					loop: Infinity
				})
			}
		}
	},
	paused: {
		rotate: 0,
		transition: {
			rotate: ({ from }) =>
			{
				return tween({
					to: from,
					key: 'rotate',
					duration: 0
				})
			}
		}
	},
	sync: {
		rotate: 0,
		transition: {
			rotate: () =>
			{
				return false
			}
		}
	},
	initialPose: 'sync'
}

const PosedGear = posed.div(poses)

const StyledGear = styled(PosedGear)`
	position: absolute;

	svg {
		${ ({ theme }) => `
			fill: ${ theme ? theme.FOREGROUND : FOREGROUND };
			color: ${ theme ? theme.FOREGROUND : FOREGROUND };
		` }
	}
`

const TOP_12T = 116
const SIZE_12T = 120

export const Gear12T = styled(StyledGear)`
	top: ${ getPercentage(TOP_12T, REFERENCE_HEIGHT) };
	left: 0;
	width: ${ getPercentage(SIZE_12T, REFERENCE_WIDTH) };
	height: ${ getPercentage(SIZE_12T, REFERENCE_HEIGHT) };
`

const SIZE_18T = 177
const LEFT_18T = 81

export const Gear18T = styled(StyledGear)`
	top: 0;
	left: ${ getPercentage(LEFT_18T, REFERENCE_WIDTH) };
	width: ${ getPercentage(SIZE_18T, REFERENCE_WIDTH) };
	height: ${ getPercentage(SIZE_18T, REFERENCE_HEIGHT) };
`

const SIZE_9T = 90
const TOP_9T = 106
const LEFT_9T = 233

export const Gear9T = styled(StyledGear)`
	top: ${ getPercentage(TOP_9T, REFERENCE_HEIGHT) };
	left: ${ getPercentage(LEFT_9T, REFERENCE_WIDTH) };
	width: ${ getPercentage(SIZE_9T, REFERENCE_WIDTH) };
	height: ${ getPercentage(SIZE_9T, REFERENCE_HEIGHT) };
`
