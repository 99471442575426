import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { omitProps } from '@janbot/alphaville-emotion'

const BlinkingCursor = styled('span', { shouldForwardProp: omitProps(['visible']) })`
	${ ({ blinking, interval }) => blinking && `
		animation: ${ interval * 2 }ms blinking step-start infinite;
	` }

	@keyframes blinking {
		50% {
			visibility: hidden;
		}
	}
`

/**
 * @typedef {object} Props
 * @property {boolean} [blinking] Whether or not the cursor should be blinking
 * @property {number}  [interval] The blinking interval in milliseconds (defaults to 500).
 */

/**
 * Cursor component.
 *
 * @augments {PureComponent<Props, {}>}
 */
class Cursor extends PureComponent
{
	static propTypes = {
		blinking: PropTypes.bool,
		interval: PropTypes.number
	}

	static defaultProps = {
		blinking: false,
		interval: 500
	}

	constructor(props)
	{
		super(props)
	}

	render()
	{
		const { blinking, interval } = this.props

		return <BlinkingCursor blinking={ blinking } interval={ interval }>_</BlinkingCursor>
	}
}

Cursor.Styled = BlinkingCursor

export default Cursor
