import { observable, action, flow } from 'mobx'
import ApolloClient from 'apollo-boost'

import VIDEO_QUERY from '../queries/Video'

export class VideoStore
{
	static query = VIDEO_QUERY

	@observable videos = new Map()
	@observable loading = new Map()
	@observable error = new Map()

	constructor()
	{
		this.client = new ApolloClient({
			uri: GRAPHQL_URL
		})
	}

	load = flow(function* (id, options = { useCache: true })
	{
		if (options.useCache)
		{
			const video = this.videos.get(id)

			if (video)
			{
				return video
			}
		}

		const result = yield this.query({ id })

		return this.parseResult(result, id)
	}.bind(this))

	query = flow(function* (variables)
	{
		const { id } = variables

		this.loading.set(id, true)
		this.error.set(id, false)

		try
		{
			const query = this.constructor.query

			const data = yield this.client.query({
				query,
				variables
			})

			if (data.video && data.video.deleted)
			{
				console.log('video deleted')
				this.error.set(id, true)
				this.loading.set(id, false)
				return null
			}

			this.loading.set(id, false)

			return data
		}
		catch (error)
		{
			console.error(error)

			this.error.set(id, true)
			this.loading.set(id, false)
		}

		return null
	})

	loadedVideo = (id) =>
	{
		return !this.loading.get(id) && (this.error.get(id) || this.videos.has(id))
	}

	hasError = (id) =>
	{
		return this.error.get(id) === true
	}

	@action
	parseResult(result, id)
	{
		if (!result || !result.data || !result.data.video)
		{
			console.warn('No video data in result')
			return null
		}

		const { video } = result.data

		this.videos.set(id, video)

		return video
	}

	getVideo = (id) =>
	{
		return this.videos.get(id)
	}
}

export default new VideoStore()
