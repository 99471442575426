import gql from 'graphql-tag'

const VIDEOS_BY_DAY_QUERY = gql`
	query getLatestVideos($since: Float) {
		latestVideos(since: $since) {
			id
			name
			created
			thumbnail(format: MEDIUM)
			topic {
				title
			}
		}
	}
`

export default VIDEOS_BY_DAY_QUERY
