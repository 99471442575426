import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import DiagonalOuterWrap from './OuterWrap'
import DiagonalInnerWrap from './InnerWrap'
import Svg from './Svg'

class Diagonal extends PureComponent
{
	static propTypes = {
		...DiagonalOuterWrap.propTypes,
		scale: PropTypes.number,
		expand: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string)
		]),
		direction: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string)
		])
	}

	static defaultProps = {
		scale: 1,
		expand: 'left right',
		direction: 'down right'
	}

	render()
	{
		const {
			scale,
			expand,
			direction,
			...rest
		} = this.props

		const size = scale * 100

		return (
			<DiagonalOuterWrap { ...rest } >
				<DiagonalInnerWrap expand={ expand }>
					<Svg direction={ direction }>
						<line x1={ 0 } y1={ 0 } x2={ `${ size }%` } y2={ `${ size }%` } />
					</Svg>
				</DiagonalInnerWrap>
			</DiagonalOuterWrap>
		)
	}
}

Diagonal.Styled = DiagonalOuterWrap

export default Diagonal
