import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@janbot/alphaville-emotion'

import Diagonal from '../Diagonal'
import Box from '../Box'
import Flex from '../Flex'
import Wrap from '../Wrap'
import Link from './Link'

const ContentWrap = styled.div`
	${ ({ open }) => !open && `
		position: absolute;
		top: -9999px;
		left: -9999px;
	` }
`

class MenuContent extends PureComponent
{
	static propTypes = {
		open: PropTypes.bool,
		links: PropTypes.arrayOf(PropTypes.shape(
			Link.propTypes
		))
	}

	static defaultProps = {
		open: true,
		links: null
	}

	render()
	{
		const {
			links,
			onClickLink,
			open,
			children
		} = this.props

		return <ContentWrap open={ open }>
			<Flex alignItems='flex-end' flexDirection='column' mr={ [0, 75] }>
				<Diagonal width={ [75, 50] } height={ 50 } expand={ ['top bottom', 'top right bottom left'] } direction={ ['right', 'left'] } />
				<Wrap mr={ [0, 50] }>
					<Box padded>
						{ links && links.map((link) => <Link key={ link.to } onClick={ onClickLink } { ...link } />) }
					</Box>
					{ children && <Diagonal height={ [50, 25] } expand='top bottom' /> }
				</Wrap>
			</Flex>
			<Wrap mr={ [0, 125] }>
				{ children }
			</Wrap>
		</ContentWrap>
	}
}

export default MenuContent
