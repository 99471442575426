import React, { PureComponent } from 'react'
import styled, {
	Z_INDEX_HOVER,
	BREAKPOINT_MAX_TABLET,
	BREAKPOINT_MAX_MOBILE
} from '@janbot/alphaville-emotion'

import Box from '../../Box'
import Diagonal from '../../Diagonal'
import Text from '../Description'

export const DescriptionWrap = styled.div`
	position: absolute;
	top: 25px;
	left: -25px;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: ${ Z_INDEX_HOVER };

	${ Diagonal.Styled } {
		position: absolute;
		bottom: 0;
		right: -25px;
	}

	${ BREAKPOINT_MAX_TABLET } {
		position: relative;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		height: auto;

		${ Diagonal.Styled } {
			position: relative;
			right: auto;
		}
	}

	${ BREAKPOINT_MAX_MOBILE } {
		${ Text.Speach } {
			display: none;
		}
	}
`

class VideoLinkDescription extends PureComponent
{
	render()
	{
		const { video } = this.props

		return <DescriptionWrap>

			<Diagonal
				expand={ ['top bottom left', 'top bottom left', 'top left'] }
				direction={ 'left' }
				width={ 25 }
				height={ 25 } />

			<Box padded
				display={ ['inline-block', 'block'] }
				width='auto'
				height='100%'
				mr={ [25, 25, 0] }>

				<Text video={ video } />
			</Box>

		</DescriptionWrap>
	}
}

export default VideoLinkDescription
