import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Timeline from './Timeline'
import Type from './Type'
import Cursor from '../Cursor'

class TypeTimeline extends Component
{
	static propTypes = {
		show: PropTypes.bool,
		lines: PropTypes.arrayOf(PropTypes.shape({
			duration: PropTypes.number,
			before: PropTypes.number,
			after: PropTypes.number,
			text: PropTypes.string
		})),
		pauseDuration: PropTypes.number,
		hideDuration: PropTypes.number,
		onComplete: PropTypes.func
	}

	static defaultProps = {
		show: true,
		lines: [],
		pauseDuration: 0.6,
		hideDuration: 0.4,
		onComplete: null
	}

	state = {
		timeline: null
	}

	componentWillMount()
	{
		this.updateTimeline()
	}

	updateTimeline()
	{
		const { lines, pauseDuration, hideDuration } = this.props

		const timeline = lines.reduce((animations, {
			before = 0,
			after = 0,
			duration
		}, index) =>
		{
			const track = `${ index }`

			if (before)
			{
				animations.push({
					track,
					from: 0,
					to: -1,
					duration: before
				})
			}

			animations.push({
				track,
				from: 0,
				to: 1,
				duration
			})

			animations.push({
				track,
				from: 1,
				to: 1,
				duration: duration * pauseDuration
			})

			animations.push({
				track,
				from: 1,
				to: 0,
				duration: duration * hideDuration
			})

			if (after)
			{
				animations.push({
					track,
					from: -1,
					to: 0,
					duration: after
				})
			}

			return animations
		}, [])

		this.setState({
			timeline
		})
	}

	render()
	{
		const {
			show,
			lines,
			onComplete
		} = this.props

		const { timeline } = this.state

		return <Timeline appear show={ show } timeline={ timeline } onComplete={ onComplete }>{
			(progress) => lines.map(({ text }, index) =>
			{
				return progress[index] !== 0 &&
					progress[index] < 0 ? <Cursor key={ `cursor-${ index }` } blinking /> :
					<Type key={ `type-${ index }` } text={ text } progress={ progress[index] } paused={ progress[index] === 1 } />
			})
		}</Timeline>
	}
}

export default TypeTimeline
