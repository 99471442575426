import Form from './Form'
import Group from './Group'
import Input from './Input'
import Label from './Label'
import Checkbox from './Checkbox'

Form.Checkbox = Checkbox
Form.Label = Label
Form.Input = Input
Form.Group = Group

export default Form
