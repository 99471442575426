import styled from '@janbot/alphaville-emotion'

import {
	keysToStyle,
	NEGATIVE_OFFSET,
	POSITIVE_OFFSET
} from './helpers'

const PROP = 'expand'
const PROP_KEYS = [
	'top',
	'left',
	'bottom',
	'right'
]

const position = keysToStyle(
	PROP,
	PROP_KEYS,
	(name) => name,
	(name, included) => (included ? NEGATIVE_OFFSET : POSITIVE_OFFSET)
)

const InnerWrap = styled.div`
	position: absolute;
	${ position }
`

export default InnerWrap
