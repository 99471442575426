import React from 'react'
import styled from '@janbot/alphaville-emotion'

const Span = styled.span()

const getTitle = (video) =>
{
	if (!video.name)
	{
		return 'untitled'
	}

	return video.name.split('_').reduce((arr, current, i, all) =>
	{
		arr.push(current)

		if (i < (all.length - 1))
		{
			arr.push('_')
			arr.push(<wbr key={ i } />)
		}

		return arr
	}, [])
}

const Title = ({ video }) => <Span>{ getTitle(video) }.mp4</Span>

Title.Styled = Span

export default Title
