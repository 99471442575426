import Player from './Player'
import Description from './Description'
import Title from './Title'
import Link from './Link'

export default {
	Player,
	Description,
	Title,
	Link
}
