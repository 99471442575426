import React, { ReactElement as ReactElementType } from 'react'

import Cursor from './Cursor'
import Wrap from './Wrap'

/**
 * Loader Component.
 *
 * @param {Object} props Properties
 * @param {string} props.text Loading text
 * @returns {ReactElementType} The component
 */
const Loader = (props) =>
{
	const  { text, ...rest } = props

	return <Wrap { ...rest }>{ text || 'LOADING' }<Cursor blinking /></Wrap>
}

export default Loader
