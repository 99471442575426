import PropTypes from 'prop-types'
import styled, {
	css,
	shouldForwardProp
} from '@janbot/alphaville-emotion'
import {
	display,
	position,
	top,
	left,
	right,
	bottom,
	zIndex,
	width,
	minWidth,
	maxWidth,
	height,
	minHeight,
	maxHeight,
	ratio,
	space,
	flex,
	order
} from 'styled-system'

const Wrap = styled('div', { shouldForwardProp })`
	${ ({ inline, clipped, ellipsis }) => css`
		${ inline && 'display: inline-block;' }
		${ clipped && 'overflow: hidden;' }
		${ ellipsis && 'text-overflow: ellipsis;' }
	` }

	${ display }
	${ position }
	${ top }
	${ left }
	${ right }
	${ bottom }
	${ zIndex }

	${ width }
	${ minWidth }
	${ maxWidth }
	${ height }
	${ minHeight }
	${ maxHeight }
	${ ratio }

	${ space }

	${ flex }
	${ order }
`

Wrap.propTypes = {
	...display.propTypes,
	...position.propTypes,
	...top.propTypes,
	...left.propTypes,
	...right.propTypes,
	...bottom.propTypes,
	...zIndex.propTypes,
	...width.propTypes,
	...minWidth.propTypes,
	...maxWidth.propTypes,
	...height.propTypes,
	...minHeight.propTypes,
	...maxHeight.propTypes,
	...ratio.propTypes,
	...space.propTypes,
	...flex.propTypes,
	...order.propTypes,
	inline: PropTypes.bool,
	clipped: PropTypes.bool,
	ellipsis: PropTypes.bool
}

Wrap.defaultProps = {
	inline: false,
	clipped: false,
	ellipsis: false
}

export default Wrap
