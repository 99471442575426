import base from './base'
import fonts from './fonts'
import typography from './typography'

let inserted = false

export default (options = { force: false }) =>
{
	if (inserted && !options.force)
	{
		return
	}

	base()
	fonts()
	typography()

	inserted = true
}
