import isPropValid from '@emotion/is-prop-valid'
import { propTypes } from 'styled-system'

import { REM_BASE_SIZE } from './constants'

export function omitProps(keys)
{
	if (!Array.isArray(keys))
	{
		keys = Object.keys(keys)
	}

	return (prop) =>
	{
		if (keys.includes(prop))
		{
			return false
		}

		return isPropValid(prop)
	}
}

export const shouldForwardProp = omitProps(propTypes)

export function parseValue(input)
{
	const [, value, unit] = input.toString().match(/(-?\d+\.?\d*)\s*([^\d]*)/)

	const number = parseFloat(value)

	return [number, unit]
}

export function calc(input, cb)
{
	const [value, unit] = parseValue(input)

	return cb(value) + unit
}

export function rem(input)
{
	if (typeof input === 'string' && isRem(input))
	{
		return input
	}

	return getRemFromPx(input)
}

export function isRem(input)
{
	return /rem$/.test(input)
}

export function getRemFromPx(px)
{
	return `${ parseFloat(px) / REM_BASE_SIZE }rem`
}

export function getPxFromRem(value)
{
	return `${ parseFloat(value) * REM_BASE_SIZE }px`
}
