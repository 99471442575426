import React from 'react'
import PropTypes from 'prop-types'
import styled, {
	boxShadow,
	css,
	BACKGROUND } from '@janbot/alphaville-emotion'

import Text from './index'
import { makeGlitch, glitchColor } from '../Glitch/Glitch'


const BaseHeader = styled(Text)`
	line-height: 1em;
	font-weight: normal;

	${ ({ theme, boxed, inline }) => css`
		${ inline && 'display: inline-block;' }
		${ boxed && css`
			padding: 16px 18px;
			background: ${ theme.BACKGROUND || BACKGROUND };
			${ boxShadow({ theme }) }
			` }
			` }


	${ ({ theme, boxed }) => theme.glitch && css`
		${ makeGlitch(theme.glitch) }
		${ boxed && css`
		${ boxShadow({ theme: { FOREGROUND: glitchColor } }) };
		` }
		` }
		`

BaseHeader.defaultProps = {
	uppercase: true,
	mt: 0,
	...BaseHeader.defaultProps
}

export const H1 = BaseHeader.withComponent('h1')

H1.defaultProps = {
	large: true,
	...BaseHeader.defaultProps
}

export const H2 = BaseHeader.withComponent('h2')

export const H3 = BaseHeader.withComponent('h3')

H3.defaultProps = {
	small: true,
	...BaseHeader.defaultProps
}

const Header = ({
	large = false,
	small = false,
	children,
	...props
}) =>
{
	const Component = large ? H1 : (small ? H3 : H2)

	return <Component { ...props }>{
		children
	}</Component>
}

Header.propTypes = {
	...Text.propTypes,
	inline: PropTypes.bool,
	boxed: PropTypes.bool,
	uppercase: PropTypes.bool,
	large: PropTypes.bool,
	small: PropTypes.bool,
}

Header.defaultProps = {
	inline: false,
	boxed: false,
	uppercase: true,
	large: false,
	small: false
}

Header.Styled = BaseHeader

export default Header
