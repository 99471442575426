import PropTypes from 'prop-types'
import {
	space,
	width
} from 'styled-system'
import styled, {
	border,
	background,
	css,
	font,
	shouldForwardProp
} from '@janbot/alphaville-emotion'

import { glitchColor, makeGlitch } from '../Glitch/Glitch'

const Button = styled('button', { shouldForwardProp })`
	text-transform: uppercase;
	cursor: pointer;
	outline: none;

	${ ({ large, square }) => css`
		${ square && `width : ${ large ? 75 : 50 }px;` }
		height: ${ large ? 75 : 50 }px;
		line-height: ${ large ? 70 : 45 }px;
		padding: 0 ${ large ? 20 : 15 }px;
		${ !square && 'text-decoration: underline;' }
	` }

	&:active {
		transform: translate(1px, 1px)
	}

	${ width }
	${ space }
	${ border }
	${ background }
	${ font }

	& + ${ () => Button } {
		margin-left: 25px;
	}


	${ ({ theme }) => theme.glitch && css`
		${ makeGlitch(theme.glitch) }
		border-color: ${ glitchColor } !important;
	` }
`


Button.propTypes = {
	...width.propTypes,
	...space.propTypes,
	large: PropTypes.bool,
	square: PropTypes.bool
}

Button.defaultProps = {
	large: false,
	square: false
}

export default Button
