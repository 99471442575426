import PropTypes from 'prop-types'
import styled, {
	css,
	border,
	background,
	shouldForwardProp,
	HEADER_MARGIN
} from '@janbot/alphaville-emotion'

import Wrap from '../Wrap'
import { glitchColor, makeGlitch } from '../Glitch/Glitch'

const Outer = styled(Wrap, { shouldForwardProp })`
	${ ({ origin }) => css`
		${ origin && `transform-origin: ${ origin };` }
	` }

	${ background }

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		${ border }
	}

	> img {
		max-width: 100%;
	}

	h1,
	h2,
	h3 {
		margin-bottom: ${ HEADER_MARGIN }px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	${ ({ theme }) => theme.glitch && css`
		background: transparent;

		&:after {
			${ makeGlitch(theme.glitch) }
			${ border({ theme: { FOREGROUND: glitchColor } }) }
		}

		p, a, label, input, div {
			${ makeGlitch(theme.glitch) }
		}

		p, a, div {
			clip-path: none;
		}

		input {
			border-color: ${ glitchColor };
		}
	` }
`

Outer.propTypes = {
	...Wrap.propTypes,
	inline: PropTypes.bool,
	clipped: PropTypes.bool,
	ellipsis: PropTypes.bool,
	innerRef: PropTypes.func
}

Outer.defaultProps = {
	...Wrap.defaultProps,
	position: 'relative',
	width: 'auto',
	flex: '0 1 auto',
	clipped: false,
	ellipsis: false
}

export default Outer
