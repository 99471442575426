import React, { Fragment } from 'react'
import { ThemeProvider, withTheme } from 'emotion-theming'
import styled, { css, keyframes } from '@janbot/alphaville-emotion'

import { getSecondsRemaining } from '../Nft/getTimeRemaining'

export const glitchColor = 'rgba(0, 0, 255, 0.8)'

export const Glitch = withTheme(({ theme, children, noLoop }) =>
{
	if (getSecondsRemaining() <= 0)
	{
		return <Fragment>{children}</Fragment>
	}

	return <Fragment>
		{ children }

		<ThemeProvider theme={ { ...theme, glitch: { top: true } } }>
			<GlitchLayer noLoop={ noLoop }>{ children }</GlitchLayer>
		</ThemeProvider>

		<ThemeProvider theme={ { ...theme, glitch: { top: false } } }>
			<GlitchLayer noLoop={ noLoop }>{ children }</GlitchLayer>
		</ThemeProvider>
	</Fragment>
})

const glitchLayer = keyframes`
	0%, 4% {
		visibility: hidden;
	}
	5%, 8% {
		visibility: visible;
	}
	9%, 100% {
		visibility: hidden;
	}
`

export const GlitchLayer = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	pointer-events: none;
	visibility: hidden;
	overflow: hidden;

	animation: ${ glitchLayer } 10s linear ${ ({ noLoop }) => (noLoop ? 1 : 'infinite') };
`

// inspired by: https://codepen.io/pgalor/pen/OeRWJQ
export const makeGlitch = (options) =>
{
	return css`
		${ makeGlitchAnimation(options) };
		mix-blend-mode: difference;
		color: ${ glitchColor };
		background: transparent;

		${ options.top ? css`
			clip-path: polygon(0 20%, 100% 20%, 100% 100%, 0 100%);
			-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 100%, 0 100%);
		` : css`
			clip-path: polygon(0 0, 100% 0, 100% 53%, 0 53%);
			-webkit-clip-path: polygon(0 0, 100% 0, 100% 53%, 0 53%);
		` }
	`
}

const makeGlitchAnimation = ({ top, transforms }) =>
{
	const currentTransforms = getCurrentTransforms(transforms)

	const glitchAnimation = currentTransforms.map((transform) =>
	{
		const options = {
			offset: top ? 1.5 : -1,
			prior: Array.isArray(transform) ? transform[1] : transform,
		}

		const animation = `
			animation: ${ makeGlitchKeyframes(options) } 1s linear infinite;
		`

		return Array.isArray(transform) ? `
			${ transform[0] } {
				${ animation }
			}
		` : animation
	}).join('')

	return glitchAnimation
}


const getCurrentTransforms = (transforms) =>
{
	if (typeof transforms === 'string')
	{
		return [cleanTransform(transforms)]
	}

	if (typeof transforms === 'object' && transforms !== null)
	{
		return getResponsiveTransform(transforms)
	}

	return ['']
}


/**
 * Return responsive transforms.
 * @param {Object|string} transforms Prior transform(s) to be applied.
 * @returns {string | [string, string]}	Array of responsive transforms.
 */
const getResponsiveTransform = (transforms) =>
{
	return Object.entries(transforms).map(([key, value]) =>
	{
		if (!value || (typeof value !== 'string' && typeof value !== 'object'))
		{
			return ''
		}

		if (typeof value === 'string')
		{
			return cleanTransform(value)
		}

		if (!value.transform)
		{
			return ''
		}

		return [key, cleanTransform(value.transform)]
	}).filter(Boolean)
}


const cleanTransform = (input = '') => (input.startsWith('transform:') ? input.split(10, -1) : input)

const makeGlitchKeyframes = ({ offset, prior = '' }) =>
{
	return keyframes`
	0% {
		transform: ${ prior } translate(0, 0);
	}
	10% {
		transform: ${ prior } translate(0, 0);
	}
	10.1% {
		transform: ${ prior } translate(${ offset * 32 }px, ${ offset * 32 }px);
	}
	15% {
		transform: ${ prior } translate(${ offset * 32 }px, ${ offset * 32 }px);
	}
	15.1% {
		transform: ${ prior } translate(${ offset * 48 }px${ offset * 48 }px);
	}
	20% {
		transform: ${ prior } translate(${ offset * 48 }px${ offset * 48 }px);
	}
	20.1% {
		transform: ${ prior } translate(0,0);
	}
	30% {
		transform: ${ prior } translate(0, 0);
	}
	30.1% {
		transform: ${ prior } translate(${ offset * 32 }px, ${ offset * 32 }px);
	}
	35% {
		transform: ${ prior } translate(${ offset * 32 }px, ${ offset * 32 }px);
	}
	35.1% {
		transform: ${ prior } translate(${ offset * 48 }px${ offset * 48 }px);
	}
	40% {
		transform: ${ prior } translate(${ offset * 48 }px${ offset * 48 }px);
	}
	40.1% {
		transform: ${ prior } translate(0,0);
	}
	61% {
		transform: ${ prior } translate(0, 0);
	}
	61.1% {
		transform: ${ prior } translate(${ offset * 32 }px, ${ offset * 32 }px);
	}
	65% {
		transform: ${ prior } translate(${ offset * 32 }px, ${ offset * 32 }px);
	}
	65.1% {
		transform: ${ prior } translate(${ offset * 48 }px, ${ offset * 48 }px);
	}
	70% {
		transform: ${ prior } translate(${ offset * 48 }px, ${ offset * 48 }px);
	}
	70.1% {
		transform: ${ prior } translate(0,0);
	}
	100% {
		transform: ${ prior } translate(0, 0);
	}
`
}
