import { SESSION_STORAGE_KEY } from './AppSession'

export const TRANSFER_GET_KEY = `__session_get_${ SESSION_STORAGE_KEY }`
export const TRANSFER_SET_KEY = `__session_set_${ SESSION_STORAGE_KEY }`

export const TRANSFER_UPDATE_KEY = `__session_update_${ SESSION_STORAGE_KEY }`

export function emitUpdate(updated)
{
	const data = JSON.stringify(updated)

	console.log('Emit update', updated)

	localStorage.setItem(TRANSFER_UPDATE_KEY, data)
	localStorage.removeItem(TRANSFER_UPDATE_KEY)
}

const updateCallbacks = []

export function registerUpdateCallback(fn)
{
	updateCallbacks.push(fn)
}

export function unregisterUpdateCallbacks()
{
	updateCallbacks.push()
}

export function receivedUpdate(value)
{
	const data = JSON.parse(value)

	if (!data)
	{
		return
	}

	for (const updateCallback of updateCallbacks)
	{
		updateCallback(data)
	}
}

/**
 * Transfers sessionStorage from one tab to another.
 * @link https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs
 * @param {Event} event The storage event
 */
function transferSessionStorage(event)
{
	// console.log('Storage event', event)

	if (!event.newValue)
	{
		return
	}

	if (event.key === TRANSFER_GET_KEY)
	{
		console.log('Get session data', SESSION_STORAGE_KEY)

		// Send to other tab using a temporary item in the Local Storage
		localStorage.setItem(TRANSFER_SET_KEY, sessionStorage.getItem(SESSION_STORAGE_KEY))

		// And then remove the temporary item
		localStorage.removeItem(TRANSFER_SET_KEY)
	}
	else if (event.key === TRANSFER_SET_KEY)
	{
		console.log('Set session data', SESSION_STORAGE_KEY, event.newValue)

		// Set incoming data from another tab
		receivedUpdate(event.newValue)
	}
	else if (event.key === TRANSFER_UPDATE_KEY)
	{
		receivedUpdate(event.newValue)
	}
}

if (typeof window !== 'undefined')
{
	window.addEventListener('storage', transferSessionStorage, false)

	// If no session data is found, get it from another tab
	if (!sessionStorage.length)
	{
		// Set a temporary item in the Local Storage to trigger a 'storage' event
		localStorage.setItem(TRANSFER_GET_KEY, 1)

		// Remove temporary item
		localStorage.removeItem(TRANSFER_GET_KEY)
	}
}
