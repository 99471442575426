import { Component } from 'react'
import GoogleAnalytics from 'react-ga'

const TRACKER_ENABLED = !!GOOGLE_ANALYTICS_ID

if (TRACKER_ENABLED)
{
	GoogleAnalytics.initialize(GOOGLE_ANALYTICS_ID)
	GoogleAnalytics.set({ anonymizeIp: true })
}

class Tracker extends Component
{
	constructor(props)
	{
		super(props)

		const { location } = this.props

		this.track(location.pathname, location.search)
	}

	componentWillReceiveProps({ location })
	{
		const { location: current } = this.props

		if (current.pathname !== location.pathname || current.search !== location.search)
		{
			this.track(location.pathname, location.search)
		}
	}

	track(pathname, search = '')
	{
		const page = pathname + search

		if (TRACKER_ENABLED)
		{
			GoogleAnalytics.set({ page })
			GoogleAnalytics.pageview(page)
		}
		else
		{
			console.log('[tracker disabled] page:', page)
		}
	}

	render()
	{
		return null
	}
}

export default Tracker
