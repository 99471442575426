import Box from './Box'
import Image from './Image'
import Fit from './Fit'
import { withScale } from '../../helpers'

Box.Scaled = withScale(Box)
Box.Scaled.defaultProps = {
	...Box.defaultProps,
	width: null,
	clipped: true
}

Box.Image = Image
Box.Image.Scaled = withScale(Image)

Box.Fit = Fit

export default Box
