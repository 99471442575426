import React, { PureComponent } from 'react'
import styled, { Z_INDEX_HOVER } from '@janbot/alphaville-emotion'

import Header from '../../Text/Header'

export const LabelWrap = styled.div`
	position: absolute;
	top: 25px;
	right: -25px;
	z-index: ${ Z_INDEX_HOVER };
`

class VideoLinkLabel extends PureComponent
{
	render()
	{
		const { text } = this.props

		return <LabelWrap>
			<Header boxed>{ text }</Header>
		</LabelWrap>
	}
}

export default VideoLinkLabel
