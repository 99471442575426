import { injectGlobal } from '../emotion'
import {
	THEMES,
	LINE_HEIGHT
} from '../index'

export default () =>
{
	injectGlobal`

		a {
			color: ${ THEMES.default.FOREGROUND };

			body.-inverted & {
				color: ${ THEMES.inverted.FOREGROUND };
			}
		}

		h1,
		h2,
		h3 {
			margin: 0;
			padding: 0;
			line-height: ${ LINE_HEIGHT };
		}

		p {
			margin-top: 0;
			margin-bottom: 1em;
			line-height: ${ LINE_HEIGHT };

			h1 + &,
			h2 + &,
			h3 + & {
				margin-top: 1em;
			}
		}

	`
}
