import React, { useEffect } from 'react'
import styled, {
	Z_INDEX_OVERLAY,
	BREAKPOINT_MAX_MOBILE,
} from '@janbot/alphaville-emotion'

import Box from '../Box/Box'
import { Faq } from './Faq/Faq'
import { Question } from './Faq/FaqItem'
import Flex from '../Flex'
import Diagonal from '../Diagonal/index'
import Button from '../Button/Button'
import { FONT_FAMILY_ALT } from '../../../../emotion/src/constants'

const MAX_WIDTH = 900

export const NftAbout = ({ onClose }) =>
{
	useEffect(() =>
	{
		document.body.style.overflow = 'hidden'

		return () =>
		{
			document.body.style.overflow = 'auto'
		}
	})

	const handleClick = (e) =>
	{
		if (e.target === e.currentTarget)
		{
			onClose()
		}
	}

	return (
		<AboutWrap onClick={ handleClick }>
			<ContentWrap>
				<CTA>
					<Button.Link
						inline
						to="https://objkt.com/collection/KT1JYrSZTEC67zHqwQ8k6ScS7wHarwUos2qZ"
					>
            Buy an NFT
					</Button.Link>
				</CTA>

				<Box padded mt={ 72 }>
					<NftHeader>Buy, Collect & Save a piece of Jan Bot</NftHeader>

					<p>
            On March 31 2023, after five years of relentless work and more than
            twenty-five thousand films produced, Jan Bot will be switched off.
					</p>
					<p>
            Upon his death, all Jan Bot films will be erased. Only those that
            are stored on the blockchain as NFTs will remain.
					</p>
					<p>
            A maximum of 500 Jan Bot films will become part of this NFT
            collection. They will be the only heritage remaining from Jan Bot.
					</p>
					<p>
            By collecting a Jan Bot NFT, you will become the owner of a Jan Bot
            artwork and contribute to preserving his legacy.
					</p>
					<p>
            The profits obtained from these NFT cards will be reused to preserve
            film heritage.
					</p>
					<p>
						<em>
              “Jan Bot is dead. Long live Jan Bot!” Is a homage to film heritage
              and the long tradition of artistic collaboration between humans
              and machines.
						</em>
					</p>
				</Box>

				<Flex alignItems="flex-end" flexDirection="column">
					<div>
						<Diagonal height={ 24 } expand={ ['top bottom'] } direction="left" />

						<Button.Link
							inline
							to="https://objkt.com/collection/KT1JYrSZTEC67zHqwQ8k6ScS7wHarwUos2qZ"
						>
              Buy, collect, save
						</Button.Link>
					</div>
				</Flex>

				<Faq />

				<Spacer />
			</ContentWrap>
		</AboutWrap>
	)
}

const NftHeader = styled.h2`
  font-family: ${ FONT_FAMILY_ALT };
  font-size: 60px;
  max-width: 528px;
  text-align: center;
  margin: 40px auto 80px !important;
  font-weight: normal;
`

const AboutWrap = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${ Z_INDEX_OVERLAY };
	overflow: auto;
	display: flex;
	justify-content: center;
	padding: 120px 40px;

	${ Box.Styled }, h2, ${ Question } {
		background: #00f;
	}

	${ Diagonal.Styled } {
		stroke: #00f;
	}

	a button {
		background: #00f;
		color: #fff;
		border: none;
		appearance: none;
	}
`

const ContentWrap = styled.div`
  max-width: ${ MAX_WIDTH }px;
`

const Spacer = styled.div`
  height: 120px;
  pointer-events: none;
`

const CTA = styled.div`
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 1;

  button {
    box-shadow: inset 0 0 0 3px #fff;
  }

  ${ BREAKPOINT_MAX_MOBILE } {
    right: 32px;
    top: 32px;
  }
`
