import gql from 'graphql-tag'

const VIDEOS_BY_DAY_QUERY = gql`
	query getVideosByDay($last: Int!, $before: String) {
		days(last: $last, before: $before) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			totalCount
			edges {
				node {
					id
					videos {
						id
						name
						created
						deleted
						thumbnail(format: MEDIUM)
						topic {
							title
						}
					}
				}
			}
		}
	}
`

export default VIDEOS_BY_DAY_QUERY
