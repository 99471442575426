import { observable, flow, action } from 'mobx'
import ApolloClient from 'apollo-boost'

import LATEST_VIDEOS_QUERY from '../queries/LatestVideos'

export class LatestVideosStore
{
	static query = LATEST_VIDEOS_QUERY

	@observable error = null
	@observable loading = false
	@observable videos = []

	constructor()
	{
		this.client = new ApolloClient({
			uri: GRAPHQL_URL
		})
	}

	load = flow(function* (since = null)
	{
		if (this.loading)
		{
			return
		}

		const results = yield this.query({
			since
		})

		this.parseResult(results)
	}.bind(this))

	query = flow(function* (variables)
	{
		this.loading = true
		this.error = null

		let data = null
		const query = this.constructor.query

		try
		{
			data = yield this.client.query({
				query,
				variables,
				fetchPolicy: 'no-cache'
			})
		}
		catch (error)
		{
			this.error = error
		}

		this.loading = false

		return data
	}.bind(this))

	@action
	parseResult(result)
	{
		if (!result || !result.data || !result.data.latestVideos)
		{
			console.warn('No data in result')
			return
		}

		const { data } = result
		const { latestVideos } = data


		this.videos = latestVideos
	}
}

export default new LatestVideosStore()
