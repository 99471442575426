import createEmotion from 'create-emotion'

import { pxToRem } from './plugins.js'

// eslint-disable-next-line no-undef
const context = typeof global !== 'undefined' ? global : {}

const options = {
	stylisPlugins: [
		pxToRem
	]
}

export const {
	flush,
	hydrate,
	cx,
	merge,
	getRegisteredStyles,
	injectGlobal,
	keyframes,
	css,
	sheet,
	caches
} = createEmotion(context, options)
