import PropTypes from 'prop-types'
import styled, {
	css,
	shouldForwardProp
} from '@janbot/alphaville-emotion'
import {
	display,
	flexWrap,
	flexDirection,
	alignItems,
	justifyContent,
} from 'styled-system'

import Wrap from './Wrap'

const Flex = styled(Wrap, { shouldForwardProp })`
	${ ({ inline }) => css`
		display: ${ inline ? 'inline-flex' : 'flex' };
	` }

	${ display }
	${ flexWrap }
	${ flexDirection }
	${ alignItems }
	${ justifyContent }
`

Flex.propTypes = {
	...Wrap.propTypes,
	...display.propTypes,
	...flexWrap.propTypes,
	...flexDirection.propTypes,
	...alignItems.propTypes,
	...justifyContent.propTypes,
	inline: PropTypes.bool
}

Flex.defaultProps = {
	...Wrap.defaultProps,
	inline: false
}

export default Flex
