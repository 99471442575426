import merge from 'deepmerge'
import { responsiveStyle } from 'styled-system'
import {
	BORDER_WIDTH,
	calc
} from '@janbot/alphaville-emotion'

export const NEGATIVE_OFFSET = calc(BORDER_WIDTH, (v) => v * -0.5)
export const POSITIVE_OFFSET = calc(BORDER_WIDTH, (v) => v * 0.5)

const styleCache = {}

export const keysToStyle = (prop, keys, property, getter) =>
{
	return (props) =>
	{
		const { [prop]: value } = props

		const cacheKey = `${ prop }: ${ value }`

		if (styleCache[cacheKey])
		{
			return styleCache[cacheKey]
		}

		let styles = keys.reduce((previous, name) =>
		{
			const cssProperty = property(name)

			return merge(previous, responsiveStyle({
				prop,
				cssProperty,
				getter: (directions) => getter(name, directions.includes(name))
			})(props))
		}, {})

		styles = joinArrayStyles(styles)

		styleCache[cacheKey] = styles

		return styles
	}
}

export function joinArrayStyles(styles)
{
	return Object.entries(styles).reduce((previous, [prop, value]) =>
	{
		previous[prop] = Array.isArray(value) ?
			value.join(prop === 'transform' ? ' ' : ', ') :
			typeof value === 'object' ? joinArrayStyles(value) : value

		return previous
	}, {})
}


