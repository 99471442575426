import React, { PureComponent } from 'react'
import styled, { PADDING } from '@janbot/alphaville-emotion'

import PageContext from './Context'

const ContentWrap = styled.div`
	padding: ${ PADDING }px;
	opacity: ${ ({ dimmed }) => (dimmed ? 0.3 : 1) };
	transition: .2s opacity;
`

class PageContent extends PureComponent
{
	render()
	{
		const { children } = this.props

		return (
			<PageContext.Consumer>
				{ ({ menuOpen }) => (
					<ContentWrap dimmed={ menuOpen }>
						{ children }
					</ContentWrap>
				) }
			</PageContext.Consumer>
		)
	}
}

export default PageContent
