import { injectGlobal } from '../emotion'
import {
	DEFAULT_SCALE,
	FONT_SIZE,
	FONT_STACK,
	THEMES
} from '../index'

export default () =>
{
	injectGlobal`
		html {
			margin: 0;
			padding: 0;
			box-sizing: border-box;
			font-size: ${ DEFAULT_SCALE };
		}

		*, *:before, *:after {
			box-sizing: inherit;
		}

		body {
			padding: 0;
			margin: 0;
			background: ${ THEMES.default.BACKGROUND };
			color: ${ THEMES.default.FOREGROUND };
			font-family: ${ FONT_STACK };
			font-size: ${ FONT_SIZE };

			&.-inverted {
				background: ${ THEMES.inverted.BACKGROUND };
				color: ${ THEMES.inverted.FOREGROUND };
			}
		}
	`
}
