import { observable, computed, flow, action, set } from 'mobx'
import ApolloClient from 'apollo-boost'

import VIDEO_STATS_QUERY from '../queries/VideoStatsQuery'
import { getSecondsRemaining } from '../components/Nft/getTimeRemaining'

export class StatsStore
{
	static queryVideo = VIDEO_STATS_QUERY

	@observable error = null
	@observable loading = false
	@observable stats = {
		videos: null,
		deletedVideos: null,
		savedVideos: null,
		days: null,
		secondsRemaining: getSecondsRemaining()
	}

	_timeRemainingInterval = null
	_refreshStatsInterval = null

	constructor()
	{
		this.client = new ApolloClient({
			uri: GRAPHQL_URL
		})

		if (typeof window !== 'undefined')
		{
			this._timeRemainingInterval = setInterval(() => this.updateTimeRemaining(), 1000)
			this._refreshStatsInterval = setInterval(() => this.loadVideoStats(), 1000)
		}
	}

	dispose()
	{
		if (this._timeRemainingInterval)
		{
			clearInterval(this._timeRemainingInterval)
		}

		if (this._refreshStatsInterval)
		{
			clearInterval(this._refreshStatsInterval)
		}
	}

	@action updateTimeRemaining()
	{
		this.stats.secondsRemaining = getSecondsRemaining()
	}

	@computed
	get isDead()
	{
		return this.stats.secondsRemaining <= 0
	}

	loadVideoStats = flow(function* ()
	{
		if (this.isDead)
		{
			return
		}

		const query = this.constructor.queryVideo

		const results = yield this.query(query)

		this.parseResult(results)
	}.bind(this))

	query = flow(function* (query, variables)
	{
		this.loading = true
		this.error = null

		let data = null

		try
		{
			data = yield this.client.query({
				query,
				variables,
				fetchPolicy: 'no-cache'
			})
		}
		catch (error)
		{
			this.error = error
		}

		this.loading = false

		return data
	}.bind(this))

	@action
	parseResult(result)
	{
		if (!result || !result.data)
		{
			console.warn('No data in result')
			return
		}

		const { data } = result
		const { stats } = data

		set(this.stats, stats)
	}
}

export default new StatsStore()
