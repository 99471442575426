import { getRemFromPx, getPxFromRem } from './utils'
import { getRenderMode } from './mode'
import { RENDER_MODE_PX } from './constants'

export function pxToRem(context, property)
{
	if (context !== 1)
	{
		return null
	}

	if (getRenderMode() === RENDER_MODE_PX)
	{
		return property.replace(/(-?\d+\.?\d*)\s*rem/g, (match, rem) => getPxFromRem(rem))
	}

	if (/stroke-width/.test(property))
	{
		return property
	}

	return property.replace(/(-?\d+\.?\d*)\s*px/g, (match, px) => getRemFromPx(px))
}
