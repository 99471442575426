import React, { PureComponent } from 'react'
import { PropTypes } from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'

import Wrap from './Wrap'
import Overlay from './Overlay'

const VIDEO_RATIO = 3 / 4

const EVENTS = {
	waiting: 'onWaiting',
	playing: 'onPlaying',
	error: 'onError',
	pause: 'onPaused',
	ended: 'onEnded',
}

const INITIAL_STATE = {
	error: false,
	waiting: false,
	ended: false,
	paused: true,
	playing: false
}

const SourceType = PropTypes.shape({
	url: PropTypes.string.isRequired,
	format: PropTypes.string.isRequired
})

export default class Player extends PureComponent
{
	static propTypes = {
		sources: PropTypes.oneOfType([
			PropTypes.arrayOf(SourceType),
			MobxPropTypes.observableArrayOf(SourceType)
		]).isRequired
	}

	constructor(props)
	{
		super(props)

		this.state = INITIAL_STATE

		this.onClick = this.onClick.bind(this)

		for (const handler of Object.values(EVENTS))
		{
			this[handler] = this[handler].bind(this)
		}
	}

	componentDidMount()
	{
		for (const [event, handler] of Object.entries(EVENTS))
		{
			this.video.addEventListener(event, this[handler])
		}
	}

	componentWillReceiveProps(nextProps)
	{
		const { url } = this.props

		if (url !== nextProps.url)
		{
			this.reset()
		}
	}

	componentWillUnmount()
	{
		for (const [event, handler] of Object.entries(EVENTS))
		{
			this.video.removeEventListener(event, this[handler])
		}
	}

	reset()
	{
		this.setState(INITIAL_STATE)
	}

	onWaiting()
	{
		this.setState({
			waiting: true,
			playing: false
		})
	}

	onPlaying()
	{
		this.setState({
			waiting: false,
			ended: false,
			paused: false,
			playing: true,
		})
	}

	onError()
	{
		this.setState({
			error: true
		})
	}

	onPaused()
	{
		this.setState({
			playing: false,
			paused: true
		})
	}

	onEnded()
	{
		this.setState({
			playing: false,
			ended: true
		})
	}

	onClick()
	{
		const { playing } = this.state

		if (!playing)
		{
			this.video.play()
		}
	}

	getReferenceHeight()
	{
		if (typeof window === 'undefined' || !window.innerHeight)
		{
			return 0
		}

		if (window.innerHeight < window.innerWidth)
		{
			return window.innerHeight
		}

		return window.innerWidth * VIDEO_RATIO
	}

	getSuitableSource()
	{
		const { sources } = this.props

		const referenceHeight = this.getReferenceHeight()

		let selected = null
		let fallback = null

		for (const source of sources)
		{
			source.height = parseInt(source.format, 10)

			if (!fallback || source.height < fallback.height)
			{
				fallback = source
			}

			if (source.height < referenceHeight && (!selected || source.height > selected.height))
			{
				selected = source
			}
		}

		return selected ? selected : fallback
	}

	render()
	{
		const { playing } = this.state

		const source = this.getSuitableSource()

		return <Wrap playing={ playing } onClick={ this.onClick }>
			<video src={ source.url } loop autoPlay muted ref={ el => { this.video = el } } />
			<Overlay { ...this.state } />
		</Wrap>
	}
}
